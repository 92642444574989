import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const FullPreview = () => {
  const [Preview, setPreview] = useState({});
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const loadPreview = async () => {
        const temp = (await (await fetch(`/api/GetFullPreview/${id}`)).json()).value[0];
        setPreview(temp);
      };
      loadPreview();
    })();
  }, [id]);

  return (
    <div className="row second">
      <div className="large-12 columns">
        <div className="panel">
          <h3>{Preview.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: Preview.content }} />
        </div>
      </div>
    </div>
  );
};

export default FullPreview;
