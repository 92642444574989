import React from "react";

const pick_result_colour = (result) => {
  if (result === "W") {
    return { color: "#5da423", letterSpacing: "0.25em" };
  } else if (result === "D") {
    return { color: "#2ba6cb", letterSpacing: "0.25em" };
  } else if (result === "L") {
    return { color: "#c60f13", letterSpacing: "0.25em" };
  } else {
    return { color: "#e9e9e9", letterSpacing: "0.25em" }; // neutral
  }
};

const manage_date_format = (kickoff_string) => {
  if (kickoff_string && kickoff_string.includes(" ")) {
    const regexDate = /\d{4}-0?(\d{1,2})-0?(\d{1,2}) (\d{2}:\d{2})/;
    const match = kickoff_string.match(regexDate);
    return `${match[2]}/${match[1]} ${match[3]} `;
  } else {
    return `${kickoff_string} `;
  }
};

const MatchSummary = ({ item, pool_title }) => {
  return (
    <tr>
      <td className="bold">
        {"M" + item.mnum}
        {pool_title.startsWith("S8 ") ? (parseInt(item.mnum) % 2 ? ["H"] : ["F"]) : null}
      </td>
      <td>
        {item.teamA}
        {pool_title.startsWith("S8 ") ? (parseInt(item.mnum) % 2 ? [" (HT)"] : [" (FT)"]) : null}
      </td>
      {pool_title.startsWith("S8 ") ? (
        parseInt(item.mnum) % 2 ? (
          <td style={{ textAlign: "center", whiteSpace: "nowrap" }} rowSpan="2">
            {item.ko === "FIELD" ? (
              item.ko
            ) : (
              <a style={{ color: "#001489" }} href={"/h2h/" + item.id}>
                {pool_title.startsWith("Megastrike")
                  ? null
                  : [
                      <span className="hide-for-small" style={pick_result_colour(item.a1)}>
                        ⬤
                      </span>,
                      <span className="hide-for-small" style={pick_result_colour(item.a2)}>
                        ⬤
                      </span>,
                      <span className="hide-for-small" style={pick_result_colour(item.a3)}>
                        ⬤
                      </span>,
                    ]}
                {manage_date_format(item.ko)}
                {pool_title.startsWith("Megastrike")
                  ? null
                  : [
                      <span className="hide-for-small" style={pick_result_colour(item.b1)}>
                        ⬤
                      </span>,
                      <span className="hide-for-small" style={pick_result_colour(item.b2)}>
                        ⬤
                      </span>,
                      <span className="hide-for-small" style={pick_result_colour(item.b3)}>
                        ⬤
                      </span>,
                    ]}
              </a>
            )}
          </td>
        ) : null
      ) : (
        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
          {item.ko === "FIELD" ? (
            item.ko
          ) : (
            <a style={{ color: "#001489" }} href={"/h2h/" + item.id}>
              {pool_title.startsWith("Megastrike")
                ? null
                : [
                    <span className="hide-for-small" style={pick_result_colour(item.a1)}>
                      ⬤
                    </span>,
                    <span className="hide-for-small" style={pick_result_colour(item.a2)}>
                      ⬤
                    </span>,
                    <span className="hide-for-small" style={pick_result_colour(item.a3)}>
                      ⬤
                    </span>,
                  ]}
              {manage_date_format(item.ko)}
              {pool_title.startsWith("Megastrike")
                ? null
                : [
                    <span className="hide-for-small" style={pick_result_colour(item.b1)}>
                      ⬤
                    </span>,
                    <span className="hide-for-small" style={pick_result_colour(item.b2)}>
                      ⬤
                    </span>,
                    <span className="hide-for-small" style={pick_result_colour(item.b3)}>
                      ⬤
                    </span>,
                  ]}
            </a>
          )}
        </td>
      )}

      <td className="righttext">
        {item.teamB}
        {pool_title.startsWith("S8 ") ? (parseInt(item.mnum) % 2 ? [" (HT)"] : [" (FT)"]) : null}
      </td>
    </tr>
  );
};

export default MatchSummary;
