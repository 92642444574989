import React from "react";
import PoolItem from "./PoolItem";

const get_pool_icon = (panel_number) => {
  if (panel_number === 1) {
    return "Soccer_6_Green_Icon.svg";
  } else if (panel_number === 2) {
    return "Soccer_10_Green_Icon.svg";
  } else if (panel_number === 3) {
    return "Soccer_13_Green_Icon.svg";
  } else if (panel_number === 4) {
    return "Soccer_4_Green_Icon.svg";
  } else if (panel_number === 5) {
    return "Soccer_ANY13X_Green_Icon.svg";
  } else if (panel_number === 6) {
    // return "soccer-Megastrike-icon-4R.png";
    // return "soccer-8-icon-4R.png";
    return "Soccer_Mega_10_Jackpot_Green_Icon.svg";
  } else if (panel_number === 7) {
    // return "soccer-8-icon-4R.png";
    return "Soccer_1_Green_Icon.svg";
  } else if (panel_number === 8) {
    // return "soccer-1-icon-4R.png";
    return "Soccer_Scores_Green_Icon.svg";
  } else if (panel_number === 9) {
    return "Soccer_Corners_Green_Icon.svg";
  }
};

const get_tab_status = (panel_number, panel_selected) => {
  if (panel_number === panel_selected) {
    return "active";
  } else {
    return "";
  }
};

const get_tab_style = (panel_number) => {
  if (panel_number === 1) {
    return { left: "0px", height: "56px" };
  } else if (panel_number === 2) {
    return { left: "55px", height: "56px" };
  } else if (panel_number === 3) {
    return { left: "110px", height: "56px" };
  } else if (panel_number === 4) {
    return { left: "165px", height: "56px" };
  } else if (panel_number === 5) {
    return { left: "220px", height: "56px" };
  } else if (panel_number === 6) {
    return { left: "275px", height: "56px" };
  } else if (panel_number === 7) {
    return { left: "330px", height: "56px" };
  } else if (panel_number === 8) {
    return { left: "385px", height: "56px" };
  } else if (panel_number === 9) {
    return { left: "440px", height: "56px" };
  }
};

const PoolPanel = ({ pools, panel_selected, panel_number, volume }) => {
  return (
    <section className={get_tab_status(panel_number, panel_selected)} style={{ paddingTop: "56px" }}>
      <p className="title" data-section-title style={get_tab_style(panel_number)}>
        <a href={"#panel" + panel_number}>
          <img src={"images/" + get_pool_icon(panel_number)} alt={"pool-icon-" + panel_number} />
        </a>
      </p>
      <div className="content" data-section-content>
        {volume === 0 ? <div className="panel">No pool currently open.</div> : ""}
        {pools.map((item) => (item.race !== "-1" ? <PoolItem item={item} /> : null))}
      </div>
    </section>
  );
};

export default PoolPanel;
