import React from "react";

const InfoRules = () => {
  return (
    <div>
      <h4>6. CHAPTER 6 &ndash; SOCCER </h4>
      <p>
        All TAB soccer pools, including<em> </em>
        <strong>
          <em>&ldquo;Soccer 13 Xtra&rdquo;</em>
        </strong>
        , are hosted by PGI on the Isle of Man and host rules apply as set out below.{" "}
        <strong>
          <em>&ldquo;Soccer 13&rdquo;</em>
        </strong>{" "}
        pools are hosted by Svenska Spel and host rules apply as set out below.
      </p>
      <p> </p>
      <h4> 6A. IN ALL POOLS THE FOLLOWING ORDER WILL APPLY: </h4>
      <p>Team A will be the team playing at &ldquo;Home&rdquo;.</p>
      <p>Team B will be the team playing &ldquo;Away&rdquo;.</p>
      <h4>
        {" "}
        <em>
          <u>EXCEPTION</u>
        </em>
        : <em>Where the match is being played at a neutral venue the order is of no consequence</em>{" "}
      </h4>
      <h4>
        <em> </em>
      </h4>
      <h4>6.1 SOCCER WIN POOL </h4>
      <p>
        <strong>6.1.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p>
        The purpose is to select the winning team and score in a specified soccer<strong> </strong>match as designated in the official soccer fixtures card for
        each Event. There are 12 possible results for each match viz., 1 - Team A to win by 1 goal, 2 &ndash; Team A to win 2 goals, 3 &ndash; Team A to win 3
        goals, 4 &ndash; Team A to win by 4 or more goals, 5 &ndash; Teams A and B to draw 0-0, 6 &ndash; Teams A and B to draw1-1, 7 &ndash; Teams A and B to
        draw 2-2 and 8 &ndash; Any other draw 9 &ndash; Team B to win by 1 goal, 10 &ndash; Team B to win by 2 goals, 11 &ndash; Team B to win by 3 goals, 12
        &ndash; Team B to win by 4 or more goals.
      </p>
      <p>
        <strong>6.1.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>
      </p>
      <p>
        Refer to Chapter 4. No Fractional betting<strong> </strong>shall be permitted.
      </p>
      <p>
        <strong>6.1.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.1.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal)
        plus any referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts.
      </p>
      <p>
        <strong>6.1.3.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL Press Association and other designated official sources.
      </p>
      <p>
        <strong>6.1.3.3</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was
        not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and
        for purposes of calculating a Dividend.
      </p>
      <p>
        <strong>6.1.4</strong> <strong>CALCULATION OF DIVIDEND</strong>: <strong>T</strong>he Dividend shall be determined by dividing the<strong> </strong>Net
        Pool by the number of unit bets on the winning selection.
      </p>
      <p>
        <strong>6.1.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </strong>
      </p>
      <p>
        Any match not completed by 9am on the day after the last day on which matches in the pool were scheduled, such match or matches shall be deemed
        abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the Operator&apos;s official source,
        notwithstanding that the Operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator reserves the
        right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed, provided this
        does not unduly hamper the declaration of results and dividends.
      </p>
      <p> </p>
      <p>
        <strong>6.1.6</strong> <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6.A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p> </p>
      <p>
        <strong>6.1.7</strong> <strong>REFUNDS: </strong>
      </p>
      <p>If the match, as designated in the official fixtures card, is abandoned, all bets shall be refunded.</p>
      <p>
        <strong>6.1.8</strong> <strong>CARRY FORWARD:</strong>{" "}
      </p>
      <p>
        Should there be no winning Tickets on the winning selection, the Net Pool shall be carried forward and added to a net soccer pool selected at the sole
        discretion of Operator Management provided that such carry forward is made within 12 months of the original pool or, in the case where it is the last
        Event of a particular season, it shall be carried forward and added to a net soccer pool within 12 months of the commencement of the new season.
      </p>
      <h4> </h4>
      <h4>6.2 SOCCER 1 POOL</h4>
      <p>
        <strong>6.2.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p>
        The purpose of the soccer 1, is to select the result of one soccer match<strong> </strong>as designated in the official soccer 1 fixtures card. There
        are six possible results for each match via, 1 - Team A to win by difference of 1 goal, 2 - Team A to win by difference of 2 or more goals, 3 &ndash; a
        no score draw (0 -0 only), 4 &ndash; a score draw (example 1- 1; 2- 2) 5 - Team B to win by difference of 1 goal and 6 - Team B to win by difference of
        2 or more goals.
      </p>
      <p>
        <strong>6.2.2</strong> <strong>UNIT OF BETTING R1-00 AND MINIMUM BET R6-00</strong>.{" "}
      </p>
      <p> Refer to Chapter 4.</p>
      <p>
        <strong>6.2.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.2.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal)
        plus any referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts.
      </p>
      <p>
        <strong>6.2.3.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL, Press Association and other designated official sources.
      </p>
      <p>
        <strong>6.2.3.3</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was
        not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and
        for purposes of calculating a Dividend.
      </p>
      <p>
        <strong>6.2.4</strong> <strong>CALCULATION OF DIVIDEND: </strong>
      </p>
      <p>
        The Dividend shall be determined by dividing the<strong> </strong>Net Pool by the total number of unit bets on the winning combination/s.
      </p>
      <p>
        <strong>6.2.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </strong>If a match or<strong> </strong>matches, as advertised, is not
        completed by 9am on the day after the last day on which matches in the pool were scheduled, shall be deemed abandoned and all choices shall qualify. The
        times at which matches are completed will be as supplied by the operator&apos;s official source, notwithstanding the operator&apos;s decision in terms
        of when a match has not been completed shall be final and binding. The Operator reserves the right to either abandon the pool in its entirety, in which
        case all bets will be refunded, or extend the time for a match to be completed, provided this does not unduly hamper the declaration of results and
        dividends.
      </p>
      <p>
        <strong>6.2.6</strong> <strong> </strong>
        <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6.A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p>
        <strong>6.2.7</strong> <strong>REFUNDS: </strong>
      </p>
      <p>
        If all the matches, as designated in the official fixtures card, are<strong> </strong>abandoned, all bets shall be refunded.
      </p>
      <p> </p>
      <h4>6.3 SOCCER 2 POOL </h4>
      <p>
        <strong>6.3.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p>
        The purpose of the soccer 2 is to select the result of two soccer matches as designated in the official soccer 2 fixtures card for each Event. There are
        six possible results for each match via, 1 - Team A to win by 1 goal, 2 - Team A to win by 2 or more goals, 3 &ndash; a no score draw (0- 0 only), 4
        &ndash; a score draw (example 1-1; 2-2) 5 - Team B to win by 1 goal and 6 - Team B to win by 2 or more goals.
      </p>
      <p>
        <strong>6.3.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>
      </p>
      <p>
        Refer to Chapter 4. No fractional betting<strong> </strong>shall be permitted.
      </p>
      <p>
        <strong>6.3.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.3.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal)
        plus any referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts.
      </p>
      <p>
        <strong>6.3.3.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL, Press Association and other designated official sources.
      </p>
      <p>
        <strong>6.3.3.3</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was
        not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and
        for purposes of calculating a Dividend.
      </p>
      <p>
        <strong>6.3.4</strong> <strong>CALCULATION OF DIVIDEND: </strong>
      </p>
      <p>
        The Dividend shall be determined by dividing the<strong> </strong>Net Pool by the total number of unit bets on the winning combination/s.
      </p>
      <p>
        <strong>6.3.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </strong>
      </p>
      <p>
        If a match or<strong> </strong>matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were
        scheduled, shall be deemed abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the operator&apos;s
        official source, notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator
        reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed,
        provided this does not unduly hamper the declaration of results and dividends.
      </p>
      <p>
        <strong>6.3.6</strong> <strong> </strong>
        <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6.A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p>
        <strong>6.3.7</strong> <strong>REFUNDS: </strong>
      </p>
      <p>
        If all the matches, as designated in the official fixtures card, are<strong> </strong>abandoned, all bets shall be refunded.
      </p>
      <p>
        <strong>6.3.8</strong> <strong>CARRY FORWARD</strong>:{" "}
      </p>
      <p>
        Should there be no winning Tickets on a soccer 2 pool, the Net Pool shall be carried forward and added to a net soccer pool, selected at the sole
        discretion of Operator Management, provided that such carry forward is made within 12 months of the original pool, in the case where it is the last
        Event of a particular season, it shall be carried forward and added to a net soccer pool within 12 months of the commencement of the new season.
      </p>
      <h4> </h4>
      <h4>6.4 SOCCER 4 POOL </h4>
      <p>
        <strong>6.4.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p>
        The purpose of the soccer 4, is to select the result of four soccer<strong> </strong>matches as designated in the official soccer 4 fixtures card for
        each Event. There are six possible results for each match via, 1 - Team A to win by 1 goal, 2 - Team A to win by 2 or more goals, 3 &ndash; a no score
        draw (0-0 only), 4 &ndash; a score draw (example 1-1; 2-2) 5 - Team B to win by 1 goal and 6 - Team B to win by 2 or more goals.
      </p>
      <p>
        <strong>6.4.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>
      </p>
      <p>
        Refer to Chapter 4. No fractional betting<strong> </strong>shall be permitted.
      </p>
      <p>
        <strong>6.4.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.4.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal)
        plus any referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts.
      </p>
      <p>
        <strong>6.4.3.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL Press Association and other designated official sources.
      </p>
      <p>
        <strong>6.4.3.3</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was
        not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and
        for purposes of calculating a Dividend.
      </p>
      <p>
        <strong>6.4.4</strong> <strong>CALCULATION OF DIVIDEND: </strong>
      </p>
      <p>
        The Dividend shall be determined by dividing the<strong> </strong>Net Pool by the total number of unit bets on the winning combination/s.
      </p>
      <p>
        <strong>6.4.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </strong>
      </p>
      <p>
        If a match or<strong> </strong>matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were
        scheduled, shall be deemed abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the operator&apos;s
        official source, notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator
        reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed,
        provided this does not unduly hamper the declaration of results and dividends.
      </p>
      <p>
        <strong>6.4.6</strong> <strong> </strong>
        <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6.A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p>
        <strong>6.4.7</strong> <strong>REFUNDS: </strong>
      </p>
      <p>
        If all the matches, as designated in the official fixtures card, are<strong> </strong>abandoned, all bets shall be refunded.
      </p>
      <p>
        <strong>6.4.8</strong> <strong>CARRY FORWARD: </strong>
      </p>
      <p>
        Should there be no winning Tickets on a soccer 4 pool, the Net Pool shall be carried forward and added to a net soccer pool, selected at the sole
        discretion of Operator Management, provided that such carry forward is made within 12 months of the original pool or, in the case where it is the last
        Event of a particular season, it shall be carried forward and added to a net soccer pool within 12 months of the commencement of the new season.
      </p>
      <h4> </h4>
      <h4>6.5 SOCCER 6 POOL (Fast play option) </h4>
      <p>
        <strong>6.5.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p>
        The purpose of the soccer 6 is to select the result of six soccer matches as designated in the official soccer 6 fixtures card for each Event. There are
        three possible results for each match viz., 1 - Team A to win, 2 - a draw and 3 &ndash; Team B to win.
      </p>
      <p>
        <strong>6.5.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>
      </p>
      <p>
        Refer to Chapter 4. No fractional betting<strong> </strong>shall be permitted.
      </p>
      <p>
        <strong>6.5.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.5.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal)
        plus any referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts.
      </p>
      <p>
        <strong>6.5.3.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL Press Association and other designated official sources.
      </p>
      <p>
        <strong>6.5.3.3</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was
        not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and
        for purposes of calculating a Dividend.
      </p>
      <p>
        <strong>6.5.4</strong> <strong>CALCULATION OF DIVIDEND: </strong>
      </p>
      <p>
        The Dividend shall be determined by dividing the<strong> </strong>Net Pool by the total number of unit bets on the winning combination/s.
      </p>
      <p>
        <strong>6.5.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES</strong>
        <strong>
          <u>:</u>
        </strong>
        <strong> </strong>
      </p>
      <p>
        If a match or<strong> </strong>matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were
        scheduled, shall be deemed abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the operator&apos;s
        official source, notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator
        reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed,
        provided this does not unduly hamper the declaration of results and dividends.
      </p>
      <p>
        <strong>6.5.6</strong> <strong> </strong>
        <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6.A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p>
        <strong>6.5.7</strong> <strong>REFUNDS: </strong>
      </p>
      <p>
        If all the matches, as designated in the official fixtures card, are<strong> </strong>abandoned, all bets shall be refunded.
      </p>
      <p>
        <strong>6.5.8</strong> <strong>CARRY FORWARD: </strong>
      </p>
      <p>
        Should there be no winning Tickets on a soccer 6 pool, the Net Pool shall be carried forward and added to a net soccer pool, selected at the sole
        discretion of Operator Management, provided that such carry forward is made within12 months of the original pool or, in the case where it is the last
        Event of a particular season, it shall be carried forward and added to a net soccer pool within 12 months of the commencement of the new season.
      </p>
      <p> </p>
      <h4>6.6 SOCCER 6 POOL (Classic play option) </h4>
      <p>
        <strong>6.6.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p>
        The purpose of the soccer 6 (classic play) is to select the result of six<strong> </strong>soccer matches as designated in the official soccer 6
        fixtures card for each Event. There are six possible results for each match via, 1 - Team A to win by 1 goal, 2 - Team A to win by 2 or more goals, 3
        &ndash; a no score draw (0-0 only), 4 &ndash; a score draw (example 1-1; 2-2) 5 - Team B to win by 1 goal and 6 - Team B to win by 2 or more goals.
      </p>
      <p>
        <strong>6.6.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>
      </p>
      <p>
        Refer to Chapter 4. No fractional betting<strong> </strong>shall be permitted.
      </p>
      <p>
        <strong>6.6.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.6.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal)
        plus any referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts.
      </p>
      <p>
        <strong>6.6.3.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL Press Association and other designated official sources.
      </p>
      <p>
        <strong>6.6.3.3</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was
        not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and
        for purposes of calculating a Dividend.
      </p>
      <p>
        <strong>6.6.4</strong> <strong>CALCULATION OF DIVIDEND: </strong>
      </p>
      <p>
        The Dividend shall be determined by dividing the<strong> </strong>Net Pool by the total number of unit bets on the winning combination/s.
      </p>
      <p>
        <strong>6.6.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </strong>
      </p>
      <p>
        If a match or<strong> </strong>matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were
        scheduled, shall be deemed abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the operator&apos;s
        official source, notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator
        reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed,
        provided this does not unduly hamper the declaration of results and dividends.
      </p>
      <p>
        <strong>6.6.6</strong> <strong> </strong>
        <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6.A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p>
        <strong>6.6.7</strong> <strong>REFUNDS:</strong>
      </p>
      <p>
        If all the matches, as designated in the official fixtures card, are<strong> </strong>abandoned, all bets shall be refunded.
      </p>
      <p>
        <strong>6.6.8</strong> <strong>CARRY FORWARD: </strong>
      </p>
      <p>
        Should there be no winning Tickets on a soccer 6 pool, the Net Pool shall be carried forward and added to a net soccer pool, selected at the sole
        discretion of Operator Management, provided that such carry forward is made within 12 months of the original pool or, in the case where it is the last
        Event of a particular season, it shall be carried forward and added to a net soccer pool within12 months of the commencement of the new season.
      </p>
      <p> </p>
      <p>
        <strong>6.7</strong> <strong>SOCCER ANY 6 POOL </strong>{" "}
      </p>
      <p>
        <strong>6.7.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p>
        The purpose of the Soccer Any 6 is to select the results of 6 soccer matches chosen from a list of 30 matches (M1 to M30) in the official Soccer Any
        fixtures card for each event. There are three possible results for each match viz., 1 - Team A to win, 2 - Draw and 3 &ndash; Team B to win.
      </p>
      <p>
        <strong>6.7.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>
      </p>
      <p> R1 with no fractional betting. Single or permutation entries.</p>
      <p>
        <strong>6.7.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.7.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal)
        plus any referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts.
      </p>
      <p>
        <strong>6.7.3.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL Press Association and other designated official sources.
      </p>
      <p>
        <strong>6.7.3.3</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was
        not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and
        for purposes of calculating a Dividend.
      </p>
      <p>
        <strong>6.7.4</strong> <strong>CALCULATION OF DIVIDEND: </strong>
      </p>
      <p>
        The Dividend shall be determined by dividing the<strong> </strong>Net Pool by the total number of unit bets on the winning combination/s.
      </p>
      <p>
        <strong>6.7.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </strong>
      </p>
      <p>
        If a match or<strong> </strong>matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were
        scheduled, shall be deemed abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the operator&apos;s
        official source, notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator
        reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed,
        provided this does not unduly hamper the declaration of results and dividends.
      </p>
      <p>
        <strong>6.7.6</strong> <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p> </p>
      <p>
        <strong>6.7.7</strong> <strong>REFUNDS:</strong>
      </p>
      <p>
        If more than 3 (three) matches in a selection of a bet in the official fixtures card of a Soccer Any 6 Pool are abandoned, then that bet shall be
        refunded.
      </p>
      <p> </p>
      <p>
        <strong>6.7.8</strong> <strong>CARRY FORWARD: </strong>
      </p>
      <p>
        Should there be no winning Tickets on a Soccer Any 6 Pool, the Net Pool shall be carried forward and added to a net Soccer Any 6 pool, selected at the
        sole discretion of Operator such that ample time is given to advertise such pools in order to maximise turnover, provided that such carry forward is
        made within 12 months of the original pool or, in the case where it is the last Event of a particular season, it shall be carried forward and added to a
        net soccer pool within12 months of the commencement of the new season.
      </p>
      <p> </p>
      <h4>6.8 SOCCER 8 POOL </h4>
      <p>
        <strong>6.8.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p>
        The purpose of the Soccer 8 is to select the result of eight soccer matches as designated in the official Soccer 8 fixtures card for each Event. There
        are three possible results for each match viz., 1 - Team A to win, 2 - a draw and 3 &ndash; Team B to win.
      </p>
      <p>
        <strong>6.8.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>
      </p>
      <p>
        Refer to Chapter 4. No fractional betting<strong> </strong>shall be permitted.
      </p>
      <p>
        <strong>6.8.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.8.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal)
        plus any referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts.
      </p>
      <p>
        <strong>6.8.3.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL Press Association and other designated official sources.
      </p>
      <p>
        <strong>6.8.3.3</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was
        not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and
        for purposes of calculating a Dividend.
      </p>
      <p>
        <strong>6.8.4</strong> <strong>CALCULATION OF DIVIDEND: </strong>
      </p>
      <p>
        The Dividend shall be determined by dividing the<strong> </strong>Net Pool by the total number of unit bets on the winning combination/s.
      </p>
      <p>
        <strong>6.8.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES</strong>
        <strong>
          <u>:</u>
        </strong>
        <strong> </strong>
      </p>
      <p>
        If a match or<strong> </strong>matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were
        scheduled, shall be deemed abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the operator&apos;s
        official source, notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator
        reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed,
        provided this does not unduly hamper the declaration of results and dividends.
      </p>
      <p>
        <strong>6.8.6</strong> <strong> </strong>
        <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6.A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p>
        <strong>6.8.7</strong> <strong>REFUNDS: </strong>
      </p>
      <p>
        If all the matches, as designated in the official fixtures card, are<strong> </strong>abandoned, all bets shall be refunded.
      </p>
      <p>
        <strong>6.8.8</strong> <strong>CARRY FORWARD: </strong>
      </p>
      <p>
        Should there be no winning Tickets on a Soccer 8 pool, the Net Pool shall be carried forward and added to a net soccer pool, selected at the sole
        discretion of Operator Management, provided that such carry forward is made within12 months of the original pool or, in the case where it is the last
        Event of a particular season, it shall be carried forward and added to a net soccer pool within 12 months of the commencement of the new season.
      </p>
      <p> </p>
      <h4>6.9 SOCCER 8 POOL HF (Half-time/Full-time)</h4>
      <h4> </h4>
      <p>
        <strong>6.9.1</strong> <strong>PURPOSE:</strong>
      </p>
      <p>
        The purpose of the Soccer 8 HF is to select the half-time and full-time results of four soccer matches as designated in the official Soccer 8 HF
        fixtures card for each Event. There are three possible results for half-time and full-time in each match: 1 - Team A to win, 2 &ndash; Draw and 3
        &ndash; Team B to win.
      </p>
      <p> </p>
      <p>
        <strong>6.9.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>Refer to Chapter 4.
      </p>
      <p>
        <strong>6.9.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.9.3.1</strong> The half-time result of a match shall be the score at the end of the normal 45-minute first-half playing period (20-minutes in
        the case of Futsal) plus any referee&rsquo;s optional time that may be played.
      </p>
      <p>
        <strong>6.9.3.2</strong> The full-time result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of
        Futsal) plus any referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts.{" "}
      </p>
      <p>
        <strong>6.9.3.3</strong> The Operator will declare a Dividend based on official half-time and full-time results of matches as provided by official
        sources of all matches, e.g. PSL, Press Association and other designated official sources.{" "}
      </p>
      <p>
        <strong>6.9.3.4</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was
        not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and
        for purposes of calculating a Dividend.
      </p>
      <p>
        <strong>6.9.4</strong> <strong>CALCULATION OF DIVIDEND: </strong>
      </p>
      <p>The Dividend shall be determined by dividing the Net Pool by the total number of unit bets on the winning combination/s.</p>
      <p> </p>
      <p>
        <strong>6.9.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </strong>
      </p>
      <p>
        If a match or matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were scheduled, shall be deemed
        abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the operator&apos;s official source,
        notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator reserves the right
        to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed, provided this does
        not unduly hamper the declaration of results and dividends.
      </p>
      <p> </p>
      <p>
        <strong>6.9.6</strong> <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6.A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p> </p>
      <p>
        <strong>6.9.7</strong> <strong>REFUNDS: </strong>
      </p>
      <p>If more than two matches, as designated in the official fixtures card, are abandoned, all bets shall be refunded.</p>
      <p> </p>
      <p>
        <strong>6.9.8</strong> <strong>CARRY FORWARD: </strong>
      </p>
      <p>
        Should there be no winning Tickets on a Soccer 8 pool, the Net Pool shall be carried forward and added to a net soccer pool, selected at the sole
        discretion of Operator Management, provided that such carry forward is made within12 months of the original pool or, in the case where it is the last
        Event of a particular season, it shall be carried forward and added to a net soccer pool within 12 months of the commencement of the new season.
      </p>
      <p> </p>
      <p> </p>
      <h4>6.10 SOCCER 10 POOL </h4>
      <p>
        <strong>6.10.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p>
        The purpose of the soccer 10 is to select the result of ten soccer<strong> </strong>matches as designated in the official soccer 10 fixtures card for
        each Event. There are three possible results for each match viz., 1 - Team A to win, 2 - a draw and 3 &ndash; Team B to win.
      </p>
      <p>
        <strong>6.10.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>
      </p>
      <p>
        Refer to Chapter 4. No fractional betting<strong> </strong>shall be permitted.
      </p>
      <p>
        <strong>6.10.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.10.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute<strong> </strong>playing period (40-minutes in the
        case of Futsal) plus any referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts
      </p>
      <p>
        <strong>6.10.3.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL, Press Association and other designated official sources.
      </p>
      <p>
        <strong>6.10.3.3</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator
        was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose
        and for purposes of calculating a Dividend.
      </p>
      <p>
        <strong>6.10.4</strong> <strong>CALCULATION OF DIVIDEND: </strong>
      </p>
      <p>
        80% of the pool shall be allocated for bets with all<strong> </strong>10 matches correct and the other 20% of the pool for entries with any nine of the
        10 matches correct. If 80% portion of pool is not won, it shall be carried forward to the 80% portion of a selected Soccer 10 pool. If no correct
        forecasts are made for the nine matches, 20% portion of the pool shall be carried forward to the 80% portion of a selected Soccer 10 pool, at the
        discretion of the Operator.
      </p>
      <p>
        <strong>6.10.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </strong>
      </p>
      <p>
        If a match or<strong> </strong>matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were
        scheduled, shall be deemed abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the operator&apos;s
        official source, notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator
        reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed,
        provided this does not unduly hamper the declaration of results and dividends.
      </p>
      <p>
        <strong> </strong>
      </p>
      <p>
        <strong>6.10.6</strong> <strong>REFUNDS: </strong>
      </p>
      <p>
        If all the matches, as designated in the official fixtures card, are<strong> </strong>abandoned, all bets shall be refunded.
      </p>
      <p>
        <strong>6.10.7</strong> <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6.A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p>
        <strong>6.10.8</strong> <strong>CARRY FORWARD: </strong>
      </p>
      <p>
        Should there be no winning Tickets on a<strong> </strong>Soccer 10 pool, the Net Pool shall be carried forward and added to a net soccer pool, selected
        at the sole discretion of Operator Management, provided that such carry forward is made within 12 months of the original pool.{" "}
      </p>
      <p> </p>
      <p> </p>
      <p>
        <strong>6.11</strong> <strong>SOCCER ANY 10 POOL </strong>{" "}
      </p>
      <p>
        <strong>6.11.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p>
        The purpose of the Soccer Any 10 is to select the results of ten soccer matches chosen from a list of 30 matches (M1 to M30) in the official Soccer Any
        fixtures card for each event. There are three possible results for each match viz., 1 - Team A to win, 2 - Draw and 3 &ndash; Team B to win.
      </p>
      <p>
        <strong>6.11.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>
      </p>
      <p>R1 with no fractional betting. Single or permutation entries.</p>
      <p>
        <strong>6.11.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.11.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute<strong> </strong>playing period (40-minutes in the
        case of Futsal) plus any referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts
      </p>
      <p>
        <strong>6.11.3.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL, Press Association and other designated official sources.
      </p>
      <p>
        <strong>6.11.3.3</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator
        was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose
        and for purposes of calculating a Dividend.
      </p>
      <p>
        <strong>6.11.4</strong> <strong>CALCULATION OF DIVIDEND: </strong>
      </p>
      <p>
        80% of the Pool shall be allocated for bets with all<strong> </strong>10 matches correct and the other 20% of the Pool for entries with any nine of the
        10 matches correct.{" "}
      </p>
      <p>
        <strong>6.11.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </strong>
      </p>
      <p>
        If a match or<strong> </strong>matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were
        scheduled, shall be deemed abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the operator&apos;s
        official source, notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator
        reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed,
        provided this does not unduly hamper the declaration of results and dividends.
      </p>
      <p>
        <strong>6.11.6</strong> <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p>
        <strong>6.11.7</strong> <strong>REFUNDS: </strong>
      </p>
      <p>
        If more than 5 (five) matches in a selection of a bet in the official fixtures card of a Soccer Any 10 Pool are abandoned, then that bet shall be
        refunded.
      </p>
      <p>
        <strong>6.11.8</strong> <strong>CARRY FORWARD: </strong>
      </p>
      <p>
        If the 80% portion of the Pool, as described in 6.9.4, above, is not won, it shall be carried forward to the 80% portion of a selected Soccer Any 10
        Pool, at the sole discretion of the Operator, such that ample time is given to advertise such pools in order to maximise turnover provided that such
        carry forward is made within 12 months of the original pool. If no correct forecasts are made for the 9 (nine) matches, as described in 6.9.4, above,
        the 20% portion of the Pool shall be carried forward to the 80% portion of a selected Soccer Any 10 Pool, at the sole discretion of the Operator, such
        that ample time is given to advertise such pools in order to maximise turnover, provided that such carry forward is made within 12 months of the
        original pool.
      </p>
      <p> </p>
      <h4>6.12 MEGA 10 JACKPOT POOL </h4>
      <h4> </h4>
      <p>
        <strong>6.12.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p>
        The purpose of the Mega 10 Jackpot pool is to select the result of ten soccer matches as designated in the official Mega 10 fixtures card for each
        Event. There are three possible results for each match viz., 1 - Team A to win, 2 - a draw and 3 &ndash; Team B to win.
      </p>
      <p>
        <strong>6.12.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>
      </p>
      <p>Refer to Chapter 4.</p>
      <p>
        <strong>6.12.3</strong> <strong>CALCULATION OF DIVIDEND:</strong>
      </p>
      <p>
        <strong>6.12.3.1</strong> 70% of the pool shall be allocated for bets with all 10 matches correct and the other 20% of the pool for entries with any
        nine of the 10 matches correct.
      </p>
      <p>
        <strong>6.12.3.2</strong> If 70% portion of pool is not won, it shall be carried forward to the 70% portion of a selected Mega 10 Jackpot pool.{" "}
      </p>
      <p>
        <strong>6.12.3.3</strong> If no correct forecasts are made for the nine matches, 20% portion of the pool shall be carried forward to the 20% portion of
        a selected Mega 10 Jackpot.
      </p>
      <p>
        <strong>6.12.3.4</strong> The remaining 10% of the Nett pool will be carried forward to the next Mega 10 Jackpot Pool to ensure the progressive jackpot
        of the Mega Jackpot Pool.
      </p>
      <p>
        <strong>6.12.3.5</strong> In the event where the Mega 10 Jackpot Nett Pool reaches R10 million (or more), and the 70% portion of the pool is not won,
        both the 70% portion and the 20% portion will be combined and shall be distributed to Customers who have selected the greatest any of the 10 matches
        correct.
      </p>
      <p> </p>
      <p> </p>
      <p>
        <strong>6.12.4</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.12.4.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period plus any referee&rsquo;s optional
        time that may be played. It shall not include extra time nor penalty shootouts
      </p>
      <p>
        <strong>6.12.4.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL, Press Association and other designated official sources.
      </p>
      <p>
        <strong>6.12.4.3</strong> Where a match started earlier than the advertised time, that is, before the close of the pool, and the operator was not in a
        position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and for
        purposes of calculating a Dividend.
      </p>
      <p>
        <strong>6.12.5</strong> <strong>ABANDONED OR CANCELLED LEGS: </strong>
      </p>
      <p>
        <strong>6.12.5.1</strong> If a match or matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were
        scheduled, the match/es shall be deemed abandoned and all choices shall qualify.{" "}
      </p>
      <p>
        <strong>6.12.5.2</strong> The times at which matches are completed will be as supplied by the operator&apos;s official source, notwithstanding the
        operator&apos;s decision in terms of when a match has not been completed shall be final and binding.{" "}
      </p>
      <p>
        <strong>6.12.5.3</strong> The Operator reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend
        the time for a match to be completed, provided this does not unduly hamper the declaration of results and dividends.
      </p>
      <p>
        <strong>6.12.6</strong> <strong>REFUNDS: </strong>
      </p>
      <p>If all the matches as designated in the official fixtures card are abandoned, all bets shall be refunded.</p>
      <p>
        <strong>6.12.7</strong> <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6.A above, the Operator shall declare, in
        accordance with the above-mentioned rule, that all selections qualify as winning selections.
      </p>
      <p> </p>
      <h4>6.13 SOCCER ANY 13 Xtra POOL (Soccer Any 13X)</h4>
      <p>
        <strong> </strong>
      </p>
      <p>
        <strong>6.13.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p>
        {" "}
        The purpose of the Soccer Any 13X is to select the results of 13 soccer matches chosen from a list of 30 matches (M1 to M30) in the official Soccer Any
        fixtures card for each event. There are three possible results for each match viz., 1 - Team A to win; 2 &ndash; Draw; 3 &ndash; Team B to win.{" "}
      </p>
      <p> </p>
      <p>
        <strong>6.13.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET:</strong> R2 with no fractional betting. Single or permutation entries.
      </p>
      <p>
        <strong>6.13.3</strong> <strong>RESULTS: </strong>
      </p>
      <p>
        <strong>6.13.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal)
        plus any referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts.{" "}
      </p>
      <p> </p>
      <p>
        <strong>6.13.3.2</strong> The Operator will declare a dividend based on official results of matches as provided by official sources of all matches, e.g.
        PSL, Press Association and other designated official sources.{" "}
      </p>
      <p> </p>
      <p>
        <strong>6.13.3.3</strong> In the event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator
        was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose
        and for purposes of calculating a dividend.{" "}
      </p>
      <p> </p>
      <p>
        <strong>6.13.4</strong> <strong>CALCULATION OF DIVIDEND:</strong>{" "}
      </p>
      <p>Division of pools (labelled A and B) as follows:</p>
      <p>A: Any 13 correct = 70%</p>
      <p>B: Any 12 correct = 30%</p>
      <p> </p>
      <p>
        <strong>6.13.5</strong> <strong>CARRY FORWARD:</strong>
      </p>
      <p>
        <strong> </strong>
      </p>
      <p>
        <strong>6.13.5.1</strong> <strong>NORMAL POOL:</strong>
      </p>
      <p> </p>
      <p>
        <strong>6.13.5.1.1</strong> If no Any 13 correct winner/s, Pool A will be carried forward to the Pool A section of the next Soccer Any 13X Pool, or any
        other net soccer pool, nominated by the Operator within 12 months of the original pool.{" "}
      </p>
      <p> </p>
      <p>
        <strong>6.13.5.1.2</strong> If no Any 12 correct winner/s, Pool B will be paid to any 11 correct winner/s, and if necessary, to any 10 correct winner/s,
        any 9 correct winner/s, any 8 correct winner/s, etc. as the case may be. If no winners, then Pool B will be carried forward to Pool A section of a
        Soccer Any 13X pool, or any other net soccer pool, nominated by the Operator.
      </p>
      <p> </p>
      <p>Note: Single entry Pool A winner does not get Pool B consolation payouts.</p>
      <p> </p>
      <p>
        <strong>6.13.6</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES</strong>: If a match or matches, as advertised, is/are not completed by 9am
        on the day after the last day on which matches in the pool were scheduled, the match/es shall be deemed abandoned and all choices shall qualify. The
        times at which matches are completed will be as supplied by the operator&apos;s official source, notwithstanding the operator&apos;s decision in terms
        of when a match has not been completed shall be final and binding. The Operator reserves the right to either abandon the pool in its entirety, in which
        case all bets will be refunded, or extend the time for a match to be completed, provided this does not unduly hamper the declaration of results and
        dividends.
      </p>
      <p>
        <strong>6.13.7</strong> <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p>
        <strong>6.13.8</strong> <strong>REFUNDS:</strong>
      </p>
      <p>
        If 18 (eighteen) or more of the 30 (thirty) matches as designated in the official fixtures card are deemed abandoned as defined in rule 6.13.6, all bets
        shall be refunded.{" "}
      </p>
      <p> </p>
      <p> </p>
      <p> </p>
      <p> </p>
      <h4>6.14 SOCCER 13 POOLS</h4>
      <h4> NOTE: </h4>
      <h4>
        {" "}
        &ldquo;Soccer 13&rdquo; is hosted by Svenska Spel in Sweden and host rules apply. Part of the rules state that the amount staked by a customer is
        limited to 60,000 sek (Sixty Thousand Swedish Krona). The value in Rand-based terms will be dependent on the prevailing South African Rand to Swedish
        Krona exchange rate. Svenska Spel reserves the right to withhold payment to any customer who breaches this limit.{" "}
      </h4>
      <h4> </h4>
      <h4>6.14.1 SATURDAY SOCCER 13 POOL </h4>
      <p> </p>
      <p>
        <strong>6.14.1.1</strong> <strong>PURPOSE:</strong>
        <strong> </strong>The purpose of the soccer 13 is to select the result of thirteen soccer matches as designated in the official soccer 13 fixtures card
        for each event. There are three possible results for each match viz., 1 &ndash; Team A to win, 2 - a draw and 3 &ndash; Team B to win.
      </p>
      <p> </p>
      <p>
        <strong>6.14.1.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET:</strong>
        <strong> </strong>R1-60. No Fractional betting shall be permitted. Unit of betting is subject to exchange rate fluctuations and may vary from time to
        time at the operator&rsquo;s discretion. Any differences between the unit of betting and the prevailing exchange rate will be allocated to an exchange
        reserve account.
      </p>
      <p> </p>
      <p>
        <strong>6.14.1.3</strong> <strong>RESULTS:</strong>
      </p>
      <p> </p>
      <p>
        <strong>6.14.1.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period plus any injury time that may be
        played. The result shall not include extra time nor penalty shootouts.
      </p>
      <p>
        <strong>6.14.1.3.2</strong> The Operator will declare a dividend based on official results of matches as provided by official sources of all matches,
        e.g. PSL, Press Association and other designated official sources. The operator will not be liable for any errors or misprints as provided by the
        official sources.
      </p>
      <p>
        <strong>6.14.1.3.3</strong> A special draw to determine the result of a match will take place if: a match commences before the start of the pool; a
        match is not concluded by the end of the pool; interrupted match. The result will be drawn from a pool of 16 lots and is a process done by a computer
        program that makes the draw under supervision of the Swedish Gaming Board. The result will be drawn from a pool consisting of: 10 lots determined by 10
        individual expert predictions, 2 lots for Home Win, 2 lots for Draw, and 2 lots for Away Win.
      </p>
      <p> </p>
      <p>
        <strong>6.14.1.4</strong> <strong>CALCULATION OF DIVIDEND:</strong>
        <strong> </strong>In the event there is one winning ticket (13 out of 13 correct) the payout will be from a Guaranteed Fund of R10, 000, 000 (Ten
        Million Rand) subject to change due to exchange rate fluctuation. Other than in that instance, the Net pool is split into winning groups as follows: 13
        out of 13 correct &ndash; 40% of Net pool, any 12 out of 13 correct &ndash; 15% of Net pool, any 11 out of 13 correct &ndash; 12% of Net pool, any 10
        out of 13 correct &ndash; 25% of Net pool, in addition, 8% of the pool is set aside for the Guaranteed Fund previously mentioned.
      </p>
      <p> </p>
      <p>
        <strong>6.14.1.4.1</strong> If a winning line is not found in any group other than the first winnings group, that winnings group will be discarded and
        its winnings total divided equally between the remaining winnings groups in the pool.
      </p>
      <p> </p>
      <p>
        <strong>6.14.1.4.2</strong> The winnings per line in lower winnings groups must not exceed the winnings per line in higher winnings groups. Surplus sums
        will be divided equally between the lines in the relevant winnings&rsquo; groups.
      </p>
      <p> </p>
      <p>
        <strong>6.14.1.4.3</strong> All dividends shall be based on the Host Tote&rsquo;s currency exchange rate as quoted by the Operator&rsquo;s principal
        banker on the day that the dividend is declared.
      </p>
      <p> </p>
      <p>
        <strong>6.14.1.4.4</strong> The Operator reserves the right to delay dividend payments until such time as net money room settlement has been affected
        between the Host tote and the operator.
      </p>
      <p> </p>
      <p>
        <strong>6.14.1.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES:</strong>
        <strong> </strong>If a game that has not started, is abandoned or postponed and the game is due to be rescheduled for play within 2 days from the
        initial scheduling of the game the bet is kept open for the game to take place and then the actual result is used before a dividend is declared. If a
        game is permanently abandoned or postponed until a day beyond the 2 days cut off, then a result is determined by the drawing of lots process (refer to
        6.14.1.3.3).
      </p>
      <p> </p>
      <p>
        <strong>6.14.1.6</strong> <strong>REFUNDS:</strong>
        <strong> </strong>If all the matches, as designated in the official fixtures card, are abandoned, all bets shall be refunded. If there is a data
        communication breakdown to host system, all bets shall be refunded.
      </p>
      <p> </p>
      <p>
        <strong>6.14.1.7</strong> <strong>ADVERTISED FIXTURES:</strong>
        <strong> </strong>If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6A above, the Operator
        shall declare, in accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p> </p>
      <p>
        <strong>6.14.1.8</strong> <strong>CARRY FORWARD:</strong> If a dividend declared within a specific winnings group is less than 15 SEK, that winnings
        group will be discarded, and its winnings total will be carried over to the first winnings group (13 out of 13) in the next week&rsquo;s pool. A
        carryover will also apply if there is no winner in the 13 out of 13 group in a pool. The winnings total for that group will be discarded and carried
        over to the first winnings group in the next week&rsquo;s pool.
      </p>
      <h4> </h4>
      <h4>6.14.2 SOCCER 13 POOLS SUNDAYS AND WEEKDAYS - hosted by Svenska Spel in Sweden and host rules apply </h4>
      <h4> </h4>
      <p>
        <strong>6.14.2.1</strong> <strong>PURPOSE:</strong>
        <strong> </strong>The purpose of the soccer 13 is to select the result of thirteen soccer matches as designated in the official soccer 13 fixtures card
        for each event. There are three possible results for each match viz., 1 &ndash; Team A to win, 2 - a draw and 3 &ndash; Team B to win.
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET:</strong>
        <strong> </strong>R2. No Fractional betting shall be permitted. Unit of betting is subject to exchange rate fluctuations and may vary from time to time
        at the operator&rsquo;s discretion. Any differences between the unit of betting and the prevailing exchange rate will be allocated to an exchange
        reserve account.
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.3</strong> <strong>RESULTS:</strong>
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period plus any injury time that may be
        played. The result shall not include extra time nor penalty shootouts.
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.3.2</strong> The Operator will declare a dividend based on official results of matches as provided by official sources of all matches,
        e.g. PSL, Press Association and other designated official sources. The operator will not be liable for any errors or misprints as provided by the
        official sources.
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.3.3</strong> A special draw to determine the result of a match will take place if: a match commences before the start of the pool; a
        match is not concluded by the end of the pool; interrupted match. The result will be drawn from a pool of 16 lots and is a process done by a computer
        program that makes the draw under supervision of the Swedish Gaming Board. The result will be drawn from a pool consisting of: 10 lots determined by 10
        individual expert predictions, 2 lots for Home Win, 2 lots for Draw, and 2 lots for Away Win.
      </p>
      <p> </p>
      <p> </p>
      <p>
        <strong>6.14.2.4</strong> <strong>CALCULATION OF DIVIDEND:</strong>
        <strong> </strong>The Net pool is split into winning groups as follows: 13 out of 13 correct &ndash; 39% of Net pool, any 12 out of 13 correct &ndash;
        22% of Net pool, any 11 out of 13 correct &ndash; 12% of Net pool, any 10 out of 13 correct &ndash; 25% of Net pool.
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.4.1</strong> If a winning line is not found in any group other than the first winnings group, that winnings group will be discarded and
        its winnings total divided equally between the remaining winnings groups in the pool.
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.4.2</strong> The winnings per line in lower winnings groups must not exceed the winnings per line in higher winnings groups. Surplus sums
        will be divided equally between the lines in the relevant winnings&rsquo; groups.
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.4.3</strong> All dividends shall be based on the Host Tote&rsquo;s currency exchange rate as quoted by the Operator&rsquo;s principal
        banker on the day that the dividend is declared.
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.4.4</strong> The Operator reserves the right to delay dividend payments until such time as net money room settlement has been affected
        between the Host tote and the operator.
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES:</strong>
        <strong> </strong>If a game that has not started, is abandoned or postponed and the game is due to be rescheduled for play within 2 days from the
        initial scheduling of the game the bet is kept open for the game to take place and then the actual result is used before a dividend is declared. If a
        game is permanently abandoned or postponed until a day beyond the 2 days cut off then a result is determined by the drawing of lots process (refer to
        6.14.2.3.3 above).
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.6</strong> <strong>REFUNDS:</strong>
        <strong> </strong>If all the matches, as designated in the official fixtures card, are abandoned, all bets shall be refunded. If there is a data
        communication breakdown to host system, all bets shall be refunded.
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.7</strong> <strong>ADVERTISED FIXTURES:</strong>
        <strong> </strong>If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6A above, the Operator
        shall declare, in accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.8</strong> <strong>CARRY FORWARD:</strong>
        <strong> </strong>If a dividend declared within a specific winnings group is less than 15 SEK, that winnings group will be discarded, and its winnings
        total will be carried over to the first winnings group (13 out of 13) in the next week&rsquo;s pool. A carryover will also apply if there is no winner
        in the 13 out of 13 group in a pool. The winnings total for that group will be discarded and carried over to the first winnings group in the next
        week&rsquo;s pool.
      </p>
      <p> </p>
      <p>
        <strong>6.14.2.9</strong> <strong>MAXIMUM BET AMOUNT:</strong> In terms of Svenska Spel rules for all Soccer 13 pools, the amount staked by a single
        customer on any one Soccer 13 pool is limited to a maximum of 60,000 sek (Sixty Thousand Swedish Krona). The value in Rand-based terms will be dependent
        on the prevailing South African Rand to Swedish Krona exchange rate. A customer may not circumvent this rule by forming a syndicate/s with other
        customers. Svenska Spel reserves the right to withhold payment of winnings to any customer who breaches this limit in any way whatsoever and thereafter
        to exclude such customer from betting into Soccer 13 pools.
      </p>
      <p> </p>
      <h4> </h4>
      <h4>6.14.3 SOCCER 13 Xtra (identified by &ldquo;13X&rdquo;) </h4>
      <h4> NOTE: &ldquo;Soccer 13 Xtra&rdquo; is hosted by the Isle of Mann and host rules apply </h4>
      <p> </p>
      <p>
        <strong>6.14.3.1</strong> <strong>PURPOSE:</strong>
        <strong> </strong>The purpose of the soccer 13 Xtra is to select the result of thirteen soccer matches as designated in the official soccer 13 Xtra
        fixtures card for each event. There are three possible results for each match viz., 1 &ndash; Team A to win, 2 - a draw and 3 &ndash; Team B to win.
      </p>
      <p> </p>
      <p>
        <strong>6.14.3.2</strong> <strong>UNIT</strong>
        <strong> OF BETTING AND MINIMUM BET:</strong>
        <strong> </strong>The unit of bet for the Soccer 13 Xtra will be: R2.00. The minimum bet will be agreed on a country-by-country basis.
      </p>
      <p> </p>
      <p>
        <strong>6.14.3.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.14.3.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period plus any referee&rsquo;s optional
        time that may be played. The result shall not include extra time nor penalty shootouts.
        <br />{" "}
      </p>
      <p>
        <strong>6.14.3.3.2</strong> The Operator will declare a dividend based on official results of matches as provided by official sources of all matches,
        e.g. PSL, Press Association and other designated official sources. The Operator will not be liable for any errors or misprints as provided by the
        official sources.
      </p>
      <p> </p>
      <p>
        <strong>6.14.3.3.3</strong> In the event that a match started earlier than the advertised time, that is, before the close of the pool, and the Operator
        was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose
        and for purposes of calculating a dividend.
      </p>
      <p> </p>
      <p>
        <strong>6.14.3.4</strong> <strong>CALCULATION OF DIVIDEND:</strong>
        <strong> </strong>
      </p>
      <p>
        <strong>6.14.3.4.1</strong> If there is only one winning ticket (13 out of 13 correct) the payout will be from a Guaranteed Fund. The guaranteed payout
        will only apply to Saturday pools and will be limited to double the payout, to a maximum of R1,000,000 (One Million Rand), should the net pool be less
        than R1,000,000 (One Million Rand).
      </p>
      <p>
        <strong>6.14.3.4.2</strong> <strong>Terms and Conditions</strong>: If the payout is R 500, 000 (Five Hundred Thousand Rand) or more, but less than R1,
        000,000 (One Million Rand), the payout will be increased to R1, 000,000 (One Million Rand). The payout will be doubled if less than R500, 000 (Five
        Hundred Thousand Rand). The following examples illustrate the aforementioned:<strong> </strong>
      </p>
      <p>
        <strong> </strong>
      </p>
      <p>
        <strong>6.14.3.4.2.1</strong> If the payout is R 700,000 (Seven Hundred Thousand Rand), then R 300,000 (Three Hundred Thousand Rand) will be added;
      </p>
      <p> </p>
      <p>
        <strong>6.14.3.4.2.2</strong> If the payout is R 200,000 (Two Hundred Thousand Rand), then it will be doubled to R 400,000 (Four Hundred Thousand Rand).{" "}
      </p>
      <p> </p>
      <p>
        <strong>6.14.3.4.3</strong> Other than in the instance described at 6.13.4.1 above, the Net pool will be divided into winning groups as follows:{" "}
      </p>
      <p>
        <strong>6.14.3.4.3.1</strong> any 13 out of 13 correct &ndash; 40% of Net pool,{" "}
      </p>
      <p>
        <strong>6.14.3.4.3.2</strong> any 12 out of 13 correct &ndash; 15% of Net pool,{" "}
      </p>
      <p>
        <strong>6.14.3.4.3.3</strong> any 11 out of 13 correct &ndash; 12% of Net pool,{" "}
      </p>
      <p>
        <strong>6.14.3.4.3.4</strong> any 10 out of 13 correct &ndash; 25% of Net pool,{" "}
      </p>
      <p>
        <strong>6.14.3.4.3.5</strong> in addition, 8% of the pool is set aside for the Guaranteed Fund mentioned above.
      </p>
      <p> </p>
      <p>
        <strong>6.14.3.4.4</strong> If no correct forecasts are made for any of the aforementioned winning groups; that portion of the pool will be carried
        forward to the 40% portion of a selected Soccer 13 Xtra pool, at the discretion of the Operator.
      </p>
      <p> </p>
      <p>
        <strong>6.14.3.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES:</strong>
        <strong> </strong>If a match or matches, as advertised, is not completed by 9am on the day after the last day on which matches in the pool were
        scheduled, shall be deemed abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the operator&apos;s
        official source, notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The operator
        reserves the right to extend the time for a match to be completed provided this does not unduly hamper the declaration of results and dividends.
      </p>
      <p> </p>
      <p>
        <strong>6.14.3.6</strong> <strong>REFUNDS:</strong>
        <strong> </strong>If all the matches, as designated in the official fixtures card, are abandoned, all bets shall be refunded.
      </p>
      <p> </p>
      <p>
        <strong>6.14.3.7</strong> <strong>CARRY FORWARD:</strong>
        <strong> </strong>Should there be no winning tickets on a soccer 13 Xtra pool, the net pool shall be carried forward and added to a net soccer pool,
        selected at the sole discretion of Operator Management, provided that such carry forward is made within twelve months of the original pool or, in the
        case where it is the last event of a particular season, it shall be carried forward and added to a net soccer pool within twelve months of the
        commencement of the new season.
      </p>
      <p> </p>
      <h4>6.15 SOCCER ANY 15 POOL </h4>
      <h4>6.15.1 PURPOSE: </h4>
      <p>
        {" "}
        The purpose of the Soccer Any 15 is to select the results of 15 soccer matches chosen from a list of 30 matches (M1 to M30) in the official Soccer Any
        fixtures card for each event. There are three possible results for each match viz., 1 - Team A to win; 2 &ndash; Draw; 3 &ndash; Team B to win.{" "}
      </p>
      <p> </p>
      <h4>6.15.2 UNIT OF BETTING AND MINIMUM BET: R1 with no fractional betting. Single or permutation entries. </h4>
      <h4>6.15.3 RESULTS: </h4>
      <h4>
        6.15.3.1 The result of a match shall be the score at the end of the normal 90-minute playing period (40-minutes in the case of Futsal) plus any
        referee&rsquo;s optional time that may be played. It shall not include extra time nor penalty shootouts.{" "}
      </h4>
      <h4>
        6.15.3.2 The Operator will declare a dividend based on official results of matches as provided by official sources of all matches, e.g. PSL, Press
        Association and other designated official sources.{" "}
      </h4>
      <h4>
        6.15.3.3 In the event that a match started earlier than the advertised time, that is, before the close of the pool, and the operator was not in a
        position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose and for
        purposes of calculating a dividend.{" "}
      </h4>
      <h4> </h4>
      <h4>6.15.4 CALCULATION OF DIVIDEND: </h4>
      <p>Division of pools (labelled A and B) as follows:</p>
      <p>A: Any 15 correct = 70%</p>
      <p>B: Any 14 correct = 30%</p>
      <p> </p>
      <h4> </h4>
      <h4>6.15.5 CARRY FORWARD:</h4>
      <h4>6.15.5.1 NORMAL POOL: </h4>
      <h4>
        6.15.5.1.1 If no Any 15 correct winner/s, Pool A will be carried forward to the Pool A section of the next Soccer Any 15 Pool or any other net soccer
        pool nominated by the Operator within 12 months of the original pool.
      </h4>
      <p> </p>
      <h4>
        6.15.5.1.2 If no Any 14 correct winner/s, Pool B will be paid to any 13 correct winner/s, and if necessary, to any 12 correct winner/s, any 11 correct
        winner/s, any 10 correct winner/s, etc. as the case may be. If no winners, then Pool B will be carried forward to Pool A section of a Soccer Any 15 pool
        or any other net soccer pool nominated by the Operator.{" "}
      </h4>
      <p> </p>
      <p>Note: Single entry Pool A winner does not get Pool B consolation payouts.</p>
      <p> </p>
      <h4>6.15.6 MUST-BE-WON POOL: </h4>
      <p>
        {" "}
        Must-be-won pools will be declared at the Operator&rsquo;s discretion or when total carryovers to Pool A is R 5, 000, 000 (Five Million Rand) or more.
        In such pools 100% of the pool will be paid out. The highest winning combination (any 15) will qualify for the dividend. If no any-15 correct winner/s,
        Pool A will be added to Pool B and the total will be paid to the highest possible winning combination i.e. any-14 correct winner/s. If no any-14 correct
        winner/s then the pool will be paid to any-13 correct winner/s, and if necessary, to any-12 correct winner/s, any-11 correct winner/s, any-10 correct
        winner/s, etc. as the case may be.
      </p>
      <p>
        <strong> </strong>
      </p>
      <h4>
        6.15.7 ABANDONED, CANCELLED AND RESCHEDULED MATCHES: If a match or matches, as advertised, is not completed by 9am on the day after the last day on
        which matches in the pool were scheduled, shall be deemed abandoned and all choices shall qualify. The times at which matches are completed will be as
        supplied by the operator&apos;s official source, notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be
        final and binding. The Operator reserves the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the
        time for a match to be completed, provided this does not unduly hamper the declaration of results and dividends.{" "}
      </h4>
      <h4> </h4>
      <h4>6.15.8 ADVERTISED FIXTURES </h4>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per Operator Rule 6A above, the Operator shall declare, in
        accordance with the above-mentioned rule that all selections qualify as winning selections.
      </p>
      <h4> </h4>
      <h4>6.15.9 REFUNDS: </h4>
      <p>
        If 16 (sixteen) or more of the 30 (thirty) matches as designated in the official fixtures card, are deemed abandoned, per rule 6.15.7, all bets shall be
        refunded.{" "}
      </p>
      <p> </p>
      <p> </p>
      <h4>6.16 TIME BOMB POOL </h4>
      <p> </p>
      <h4>6.16.1 PURPOSE: </h4>
      <p>
        The object of the bet is to forecast the time frame (as per defined time frames below) when the first goal, which shall include an own goal, in each of
        five designated soccer matches (Match 1 or M1 to Match 5 or M5) is scored.
      </p>
      <p> </p>
      <h4>6.16.2 Time Bomb pools are hosted on the Isle of Man and host rules apply as set out below. </h4>
      <p> </p>
      <h4>6.16.3 BET DETAILS </h4>
      <p>
        There are 30 options or time frames to choose from in each of the five soccer matches. Each option or time frame is allocated a number (see table
        below). Use the number/s to make your selection/s in each match. The number for each option or time frame is below:
      </p>
      <p> </p>
      <h4>6.16.3.1 1 = Minutes 1 &amp; 2 (goal scored in first or second minute) </h4>
      <h4>6.16.3.2 2 = Minutes 3, 4 &amp; 5 (goal scored in third, fourth or fifth minute) </h4>
      <h4>6.16.3.3 3 = Minutes 6, 7 &amp; 8 </h4>
      <h4>6.16.3.4 4 = Minutes 9, 10 &amp; 11 </h4>
      <h4>6.16.3.5 5 = Minutes 12, 13 &amp; 14 </h4>
      <h4>6.16.3.6 6 = Minutes 15, 16 &amp; 17 </h4>
      <h4>6.16.3.7 7 = Minutes 18, 19 &amp; 20 </h4>
      <h4>6.16.3.8 8 = Minutes 21, 22 &amp; 23 </h4>
      <h4>6.16.3.9 9 = Minutes 24, 25 &amp; 26 </h4>
      <h4>6.16.3.10 10 = Minutes 27, 28 &amp; 29 </h4>
      <h4>6.16.3.11 11 = Minutes 30, 31 &amp; 32 </h4>
      <h4>6.16.3.12 12 = Minutes 33, 34 &amp; 35 </h4>
      <h4>6.16.3.13 13 = Minutes 36, 37 &amp; 38 </h4>
      <h4>6.16.3.14 14 = Minutes 39, 40, 41 &amp; 42 </h4>
      <h4>
        6.16.3.15 15 = Minutes 43, 44, 45 &amp; any added minutes to half-time (goal scored from 43<sup>rd</sup> minute until half-time){" "}
      </h4>
      <p>
        <strong> </strong>
      </p>
      <p>
        <strong>HALF-TIME</strong>
      </p>
      <p> </p>
      <h4>6.16.3.16 16 = Minutes 46, 47 &amp; 48 </h4>
      <h4>6.16.3.17 17 = Minutes 49, 50 &amp; 51 </h4>
      <h4>6.16.3.18 18 = Minutes 52, 53 &amp; 54 </h4>
      <h4>6.16.3.19 19 = Minutes 55, 56 &amp; 57 </h4>
      <h4>6.16.3.20 20 = Minutes 58, 59 &amp; 60 </h4>
      <h4>6.16.3.21 21 = Minutes 61, 62 &amp; 63 </h4>
      <h4>6.16.3.22 22 = Minutes 64, 65 &amp; 66 </h4>
      <h4>6.16.3.23 23 = Minutes 67, 68 &amp; 69 </h4>
      <h4>6.16.3.24 24 = Minutes 70, 71, 72 &amp; 73 </h4>
      <h4>6.16.3.25 25 = Minutes 74, 75, 76 &amp; 77 </h4>
      <h4>6.16.3.26 26 = Minutes 78, 79, 80 &amp; 81 </h4>
      <h4>6.16.3.27 27 = Minutes 82, 83, 84 &amp; 85 </h4>
      <h4>6.16.3.28 28 = Minutes 86, 87, 88 &amp; 89 </h4>
      <h4>
        6.16.3.29 29 = Minute 90 &amp; any added minutes to full-time (goal scored from 90<sup>th</sup> minute until end of normal 90-minute playing period,
        which shall include added time but not extra time and penalty shootouts){" "}
      </h4>
      <h4>6.16.3.30 30 = NO GOALS SCORED IN MATCH </h4>
      <p> </p>
      <p>
        <strong>
          <u>NOTE:</u>
        </strong>
      </p>
      <p>
        <strong> </strong>
      </p>
      <p>
        For result purposes the time or time frame the first goal is scored in a match will be as supplied by the operator&apos;s official source,
        notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be absolutely final and binding.
      </p>
      <p> </p>
      <h4>6.16.4 UNIT OF BETTING &amp; MINIMUM BET </h4>
      <p>R1 unit with no fractional betting and R1 minimum bet</p>
      <p> </p>
      <h4>6.16.5 SINGLE &amp; PERMUTATION BETS </h4>
      <p>
        Single or multiple bets may be played. A single bet is one selection in each match and costs R1. With a multiple or permutation entry, any number of
        selections can be made in each match and the cost is R1 per combination. Calculate the cost of a multiple entry by multiplying out the number of
        selections in each match.
      </p>
      <p> </p>
      <h4>6.16.6 QUICK PICK </h4>
      <p>A Quick Pick is a single entry bet and the selection in each match will be randomly chosen by the betting system.</p>
      <p> </p>
      <h4>6.16.7 DIVISION OF POOL (labelled A, B, C and D) as follows: </h4>
      <p> </p>
      <h4>6.16.7.1 A: All 5 correct = 40% </h4>
      <h4>6.16.7.2 B: Any 4 correct = 30% </h4>
      <h4>6.16.7.3 C: Any 3 correct = 25% </h4>
      <h4>6.16.7.4 D: Reserve fund = 5% </h4>
      <p> </p>
      <p>
        <strong>
          <u>NOTES</u>
        </strong>
        <strong>:</strong>
      </p>
      <p>
        <strong> </strong>
      </p>
      <h4>6.16.8 NORMAL POOL </h4>
      <p> </p>
      <h4>
        6.16.8.1 1 &ndash; If no all 5 correct winner/s, Pool A is carried forward to Pool A of next Time Bomb pool or a Time Bomb pool nominated by the
        operator.{" "}
      </h4>
      <h4>6.16.8.2 2 &ndash; If no any 4 correct winner/s, Pool B is added to Pool C* and total of both pools goes to any 3 correct winner/s. </h4>
      <h4>
        6.16.8.3 3 &ndash; If no any 4 correct nor any 3 correct, then total of Pools B and C paid to any 2 correct winner/s. If there are no any 2 correct
        winners, then the totals of Pools B and C will be divided among all any 1 correct winner/s.{" "}
      </h4>
      <h4>
        6.16.8.4 * Consequently at least 55% of every Time Bomb pool should be paid out. In the event that no entry has even one match correct, then 95% of the
        pool will be carried forward to the next Pool A of Time Bomb.{" "}
      </h4>
      <h4> </h4>
      <h4>6.16.9 MUST-BE WON POOL </h4>
      <p>Must-be-won pools will be declared at the Operator&rsquo;s discretion or when total of carryovers to Pool A is R10 million or more.</p>
      <p>
        In such pools 95% will be paid out &ndash; if no all 5 correct winner/s, Pool A added to Pool B and total paid to any 4 correct; if no all 5 correct nor
        any 4 correct winner/s, then total of Pools A, B and C paid to any 3 correct, or any 2 or 1 if necessary.
      </p>
      <p> </p>
      <h4>6.16.10 RESERVE FUND </h4>
      <p>
        5% of any Time Bomb pool goes to Reserve Fund, which will be added to Pool A of selected Time Bomb pools from time to time at the discretion of the
        operator.
      </p>
      <p> </p>
      <h4>6.16.11 MATCHES NOT PLAYED/COMPLETED </h4>
      <p>
        Any match in a Time Bomb pool not completed by 9am on the day after the last day on which matches in the pool were scheduled, shall be deemed abandoned
        and all choices shall qualify. In such an event the A portion of the pool shall automatically be carried over and all entries shall qualify only for
        payouts emanating from the B and C portions of the pool. The times at which matches are completed will be as supplied by the operator&apos;s official
        source, notwithstanding the operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator reserves
        the right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed, provided
        this does not unduly hamper the declaration of results and dividends.
      </p>
      <p> </p>
      <h4>6.17 SOCCER 6 CORRECT SCORES POOL </h4>
      <h4> </h4>
      <p>
        <strong>IN ALL POOLS THE FOLLOWING ORDER WILL APPLY:</strong>
      </p>
      <p>
        <strong>Team A will be the team playing at &ldquo;Home&rdquo;.</strong>
      </p>
      <p>
        <strong>Team B will be the team playing &ldquo;Away&rdquo;.</strong>
      </p>
      <p>
        <strong> </strong>
      </p>
      <p>
        <strong>
          <em>EXCEPTION</em>
        </strong>
        <strong>:</strong>
        <strong> </strong>
        <strong>
          <em>Where the match is being played at a neutral venue the order is of no consequence</em>
        </strong>
      </p>
      <h4> </h4>
      <p>
        <strong>6.17.1</strong> <strong>PURPOSE: </strong>
      </p>
      <ul>
        <li>
          TAB will select a pick 6 (six matches) and pick 8 (eight matches) from official matches scheduled. The purpose is to select the correct score in a
          specified soccer match as designated in the official soccer fixtures card for each Event. There are 9 possible results per match{" "}
          <strong>
            viz 1. Any Team to win 1-0, 2. Any Team to win 2-0, 3. Any Team to win 3-0, 4. Any Team to win 2-1, 5. Any Team to win 3-1, 6. Any Team to win 3-2,
            7. Any Team to win by any other score, 8.Goalless draw 0-0, 9. Score
          </strong>
          <strong> </strong>
          <strong>draw.</strong>
        </li>
      </ul>
      <p>
        <strong>6.17.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>
      </p>
      <p>
        Minimum bet R2 and increments of R2. No fractional betting<strong> </strong>shall be permitted.
      </p>
      <p>
        <strong>6.17.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.17.3.1</strong> The result of a match shall be the score at the end of the normal 90-minute playing period plus any referee&rsquo;s optional
        time that may be played. It shall not include extra time nor penalty shootouts.
      </p>
      <p> </p>
      <p>
        <strong>6.17.3.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL Press Association and other designated official sources.
      </p>
      <p> </p>
      <p>
        <strong>6.17.3.3</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the Operator
        was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose
        and for purposes of calculating a Dividend.
      </p>
      <p> </p>
      <p>
        <strong>6.17.3.4</strong> The jackpot will be paid out to winning combinations with all six correct option and a consolation will be paid out to highest
        winning combination below six (5 out of 6 or below)
      </p>
      <p> </p>
      <p>
        <strong>6.17.4</strong> <strong>CALCULATION OF DIVIDEND: </strong>
      </p>
      <p> </p>
      <p>
        <strong>6.17.4.1</strong> The dividend shall be determined by dividing the net pool by the total number of unit bets on the winning combination. The net
        pool shall be divided in a major share (60%) and a minor share (40%) portion.{" "}
      </p>
      <p> </p>
      <p>
        <strong>6.17.4.2</strong> The major share of the Soccer Correct Score pool shall be distributed to the ticket holders that selected all six correct
        options.{" "}
      </p>
      <p> </p>
      <p>
        <strong>6.17.4.3</strong> The minor share of the Soccer Correct Score pool shall be distributed to ticket holders that selected the second greatest
        correct options.
      </p>
      <p> </p>
      <p>
        <strong>6.17.4.4</strong> If there are no wagers selecting all the correct options of the Soccer Correct Score pool, then the major share will be
        carried forward at the Operator management&apos;s discretion to any pool within 12 months of the original pool.
      </p>
      <p> </p>
      <p>
        <strong>6.17.4.5</strong> If there are no wagers selecting the second greatest correct options, then the minor share shall be distributed to ticket
        holders selecting the next greatest correct options of the Soccer Correct Score pool. If there are no winning selections, then the minor share shall be
        carried forward at the Operator management&apos;s discretion to any pool within 12 months of the original pool.
      </p>
      <p> </p>
      <p>
        <strong>6.17.4.6</strong> If there are no wagers from both the Major and Minor shares, then 100% of the Net pool will be carried forward at the Operator
        management&apos;s discretion to any pool within 12 months of the original pool.
      </p>
      <p> </p>
      <p>
        <strong>6.17.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </strong>
      </p>
      <p>
        Any match not completed by 9am on the day after the last day on which matches in the pool were scheduled, such match or matches shall be deemed
        abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the Operator&apos;s official source,
        notwithstanding that the Operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator reserves the
        right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed, provided this
        does not unduly hamper the declaration of results and dividends.
      </p>
      <p>
        <strong>6.17.6</strong> <strong>REFUNDS: </strong>
      </p>
      <p>
        If all matches, as designated in the official fixtures card, are<strong> </strong>abandoned, all bets shall be refunded.
      </p>
      <p>
        <strong>6.17.7</strong> <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per the Operator&rsquo;s fixtures layout, the Operator shall
        declare that all selections qualify as winning selections.
      </p>
      <p>
        <strong>6.17.8</strong> <strong>CARRY FORWARD: </strong>
      </p>
      <p>
        Should there be no winning Tickets on The Soccer Correct Scores Pool, the Net Pool shall be carried forward and added to a net soccer pool, selected at
        the sole discretion of Operator Management, provided that such carry forward is made within 12 months of the original pool, or, in the case where it is
        the last Event of a particular season, it shall be carried forward and added to a net soccer pool within 12 months of the commencement of the new
        season.{" "}
      </p>
      <p> </p>
      <p> </p>
      <p>
        <strong>6.18</strong> <strong>SOCCER TOTAL CORNERS POOL</strong>
      </p>
      <p>
        <strong> </strong>
      </p>
      <p>
        <strong>6.18.1</strong> <strong>PURPOSE: </strong>
      </p>
      <p> </p>
      <p>
        <strong>6.18.1.1</strong> TAB will select a pick 6 (six matches) from official matches scheduled.
      </p>
      <p> </p>
      <p>
        <strong>6.18.1.2</strong> The purpose is to select the total corners in specified soccer matches as designated in the official soccer fixtures card for
        each Event. There are 5 possible results per match viz Match 1- 1=0-7 corners, 2=8 corners, 3=9 corners, 4=10 corners, 5=11+corners. These options will
        apply to matches 2,3,4,5,6 as well.
      </p>
      <p> </p>
      <p>
        <strong>6.18.2</strong> <strong>UNIT OF BETTING AND MINIMUM BET: </strong>
      </p>
      <p>
        Minimum bet R2 and increments of R2. No fractional betting<strong> </strong>shall be permitted.
      </p>
      <p>
        <strong> </strong>
      </p>
      <p>
        <strong>6.18.3</strong> <strong>RESULTS:</strong>
      </p>
      <p>
        <strong>6.18.3.1</strong> The total corners at the end of the normal 90-minute playing period plus any referee&rsquo;s optional time that may be played
        for each match in the pool will be determined by the official stats of the matches provided by the operator&rsquo;s official sources. It shall not
        include extra time nor penalty shootouts.
      </p>
      <p>
        <strong>6.18.3.2</strong> The Operator will declare a Dividend based on Official Results of matches as provided by official sources of all matches, e.g.
        PSL Press Association and other designated official sources.
      </p>
      <p>
        <strong>6.18.3.3</strong> In the Event that a match started earlier than the advertised time, that is, before the close of the pool, and the Operator
        was not in a position to close the pool at the start of such a match, all possible results/combinations will apply as the final result for that purpose
        and for purposes of calculating a Dividend.
      </p>
      <p> </p>
      <p>
        <strong>6.18.4</strong> <strong>CALCULATION OF DIVIDEND: </strong>
      </p>
      <p> The Dividend shall be determined by dividing the Net Pool by the total number of unit bets on the winning combinations/s.</p>
      <p> </p>
      <p>
        <strong>6.18.5</strong> <strong>ABANDONED, CANCELLED AND RESCHEDULED MATCHES: </strong>
      </p>
      <p>
        Any match not completed by 9am on the day after the last day on which matches in the pool were scheduled, such match or matches shall be deemed
        abandoned and all choices shall qualify. The times at which matches are completed will be as supplied by the Operator&apos;s official source,
        notwithstanding that the Operator&apos;s decision in terms of when a match has not been completed shall be final and binding. The Operator reserves the
        right to either abandon the pool in its entirety, in which case all bets will be refunded, or extend the time for a match to be completed, provided this
        does not unduly hamper the declaration of results and dividends.
      </p>
      <p>
        <strong> </strong>
      </p>
      <p>
        <strong>6.18.6</strong> <strong>REFUNDS: </strong>
      </p>
      <p>
        If all matches, as designated in the official fixtures card, are<strong> </strong>abandoned, all bets shall be refunded.
      </p>
      <p>
        <strong> </strong>
      </p>
      <p>
        <strong>6.18.7</strong> <strong>ADVERTISED FIXTURES</strong>
      </p>
      <p>
        If, for any reason, the advertised fixture/s in the official programmes are not in order as per the Operator&rsquo;s fixtures layout, the Operator shall
        declare that all selections qualify as winning selections.
      </p>
      <p>
        <strong> </strong>
      </p>
      <p>
        <strong>6.18.8</strong> <strong>CARRY FORWARD: </strong>
      </p>
      <p>
        Should there be no winning Tickets on The Soccer Correct Scores Pool, the Net Pool shall be carried forward and added to a net soccer pool, selected at
        the sole discretion of Operator Management, provided that such carry forward is made within 12 months of the original pool, or, in the case where it is
        the last Event of a particular season, it shall be carried forward and added to a net soccer pool within 12 months of the commencement of the new
        season.{" "}
      </p>
      <p> </p>
    </div>
  );
};

export default InfoRules;
