import React from "react";

const manage_date_format = (kickoff_string) => {
  if (kickoff_string.includes(" ")) {
    const regexDate = /(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2})/;
    const match = kickoff_string.match(regexDate);
    let d = new Date(match[1]);
    // const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    // const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return d.getDate() + "/" + (d.getMonth() + 1) + " " + match[2];
  } else {
    return `${kickoff_string} `;
  }
};

const pick_home_team_colour = (home_score, away_score, status) => {
  if (status === "Not Started") {
    return { color: "silver" };
  } else {
    if (parseInt(home_score) > parseInt(away_score)) {
      if (status === "Finished") {
        return { fontWeight: "bold", fontSize: "1.25em", color: "darkslateblue" };
      } else {
        return { fontWeight: "bold", fontSize: "1.25em", color: "tomato" };
      }
    } else {
      if (status === "Finished") {
        return { color: "lightslategrey", fontSize: "1.25em" };
      } else {
        return { color: "orange", fontSize: "1.25em" };
      }
    }
  }
};

const pick_away_team_colour = (home_score, away_score, status) => {
  if (status === "Not Started") {
    return { color: "silver" };
  } else {
    if (parseInt(home_score) < parseInt(away_score)) {
      if (status === "Finished") {
        return { fontWeight: "bold", fontSize: "1.25em", color: "darkslateblue" };
      } else {
        return { fontWeight: "bold", fontSize: "1.25em", color: "tomato" };
      }
    } else {
      if (status === "Finished") {
        return { color: "lightslategrey", fontSize: "1.25em" };
      } else {
        return { color: "orange", fontSize: "1.25em" };
      }
    }
  }
};

const pick_result_colour = (home_score, away_score, status) => {
  if (status === "Not Started") {
    return { color: "silver", textAlign: "center", display: "block" };
  } else {
    if (parseInt(home_score) === parseInt(away_score)) {
      if (status === "Finished") {
        return { fontWeight: "bold", fontSize: "1.25em", color: "darkslateblue", textAlign: "center", display: "block" };
      } else {
        return { fontWeight: "bold", fontSize: "1.25em", color: "tomato", textAlign: "center", display: "block" };
      }
    } else {
      if (status === "Finished") {
        return { color: "lightslategrey", fontSize: "1.25em", textAlign: "center", display: "block" };
      } else {
        return { color: "orange", fontSize: "1.25em", textAlign: "center", display: "block" };
      }
    }
  }
};

const pick_projection = (home_score, away_score, status, venue) => {
  if (status === "Not Started") {
    return <span style={{ textAlign: "center", display: "block" }}>?</span>;
  } else {
    if (venue.startsWith("SS ")) {
      if ((parseInt(home_score) === 1 && parseInt(away_score) === 0) || (parseInt(home_score) === 0 && parseInt(away_score) === 1)) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>1</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>1</span>;
        }
      } else if ((parseInt(home_score) === 2 && parseInt(away_score) === 0) || (parseInt(home_score) === 0 && parseInt(away_score) === 2)) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>2</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>2</span>;
        }
      } else if ((parseInt(home_score) === 3 && parseInt(away_score) === 0) || (parseInt(home_score) === 0 && parseInt(away_score) === 3)) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>3</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>3</span>;
        }
      } else if ((parseInt(home_score) === 2 && parseInt(away_score) === 1) || (parseInt(home_score) === 1 && parseInt(away_score) === 2)) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>4</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>4</span>;
        }
      } else if ((parseInt(home_score) === 3 && parseInt(away_score) === 1) || (parseInt(home_score) === 1 && parseInt(away_score) === 3)) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>5</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>5</span>;
        }
      } else if ((parseInt(home_score) === 3 && parseInt(away_score) === 2) || (parseInt(home_score) === 2 && parseInt(away_score) === 3)) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>6</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>6</span>;
        }
      } else if (parseInt(home_score) === 0 && parseInt(away_score) === 0) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>8</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>8</span>;
        }
      } else if (parseInt(home_score) === parseInt(away_score) && parseInt(home_score) > 0) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>9</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>9</span>;
        }
      } else {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>7</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>7</span>;
        }
      }
    } else if (venue.startsWith("S4 ")) {
      if (parseInt(home_score) === parseInt(away_score) + 1) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>1</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>1</span>;
        }
      } else if (parseInt(home_score) > parseInt(away_score) + 1) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>2</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>2</span>;
        }
      } else if (parseInt(home_score) === parseInt(away_score) && parseInt(home_score) === 0) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>3</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>3</span>;
        }
      } else if (parseInt(home_score) === parseInt(away_score) && parseInt(home_score) > 0) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>4</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>4</span>;
        }
      } else if (parseInt(away_score) === parseInt(home_score) + 1) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>5</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>5</span>;
        }
      } else if (parseInt(away_score) > parseInt(home_score) + 1) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>6</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>6</span>;
        }
      }
    } else if (venue.startsWith("S4 ") || venue.startsWith("S1 ")) {
      if (parseInt(home_score) === parseInt(away_score) + 1) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>1</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>1</span>;
        }
      } else if (parseInt(home_score) > parseInt(away_score) + 1) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>2</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>2</span>;
        }
      } else if (parseInt(home_score) === parseInt(away_score) && parseInt(home_score) === 0) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>3</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>3</span>;
        }
      } else if (parseInt(home_score) === parseInt(away_score) && parseInt(home_score) > 0) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>4</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>4</span>;
        }
      } else if (parseInt(away_score) === parseInt(home_score) + 1) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>5</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>5</span>;
        }
      } else if (parseInt(away_score) > parseInt(home_score) + 1) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>6</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>6</span>;
        }
      }
    } else if (venue.startsWith("SC ")) {
    } else if (venue.startsWith("S8 ")) {
    } else {
      if (parseInt(home_score) > parseInt(away_score)) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>1</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>1</span>;
        }
      } else if (parseInt(home_score) === parseInt(away_score)) {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>2</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>2</span>;
        }
      } else {
        if (status === "Finished") {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", textAlign: "center", display: "block" }}>3</span>;
        } else {
          return <span style={{ fontWeight: "bold", color: "tomato", textAlign: "center", display: "block" }}>3</span>;
        }
      }
    }
  }
};

const LiveMatchSummaryWide = ({ item, pool_title }) => {
  return (
    <>
      <tr>
        <td style={{ width: "3%", fontWeight: "bold", paddingTop: 0, paddingBottom: 0, backgroundColor: item.mnum % 2 === 0 ? "#fafafa" : "#f2f2f2" }}>
          {"M" + item.mnum}
          {pool_title.startsWith("S8 ") ? (parseInt(item.mnum) % 2 ? ["H"] : ["F"]) : null}
        </td>
        <td style={{ width: "14%", fontWeight: "bold", paddingTop: 0, paddingBottom: 0, backgroundColor: item.mnum % 2 === 0 ? "#fafafa" : "#f2f2f2" }}>
          <center>
            {item.match_status === "Not Started" ? (
              <>{manage_date_format(item.ko)}</>
            ) : item.match_status === "Finished" ? (
              "FT"
            ) : item.time === "HT" ? (
              "HT"
            ) : (
              <span className="blink">{item.time}</span>
            )}
            <br />
          </center>
        </td>
        <td style={{ width: "3%", paddingTop: 0, paddingBottom: 0, backgroundColor: item.mnum % 2 === 0 ? "#fafafa" : "#f2f2f2" }}>
          {pick_projection(item.home_score, item.away_score, item.match_status, pool_title)}
        </td>
        <td style={{ width: "34%", backgroundColor: item.mnum % 2 === 0 ? "#fafafa" : "#f2f2f2" }} rowSpan={2}>
          <span style={pick_home_team_colour(item.home_score, item.away_score, item.match_status)}>
            {item.teamA}
            {pool_title.startsWith("S8 ") ? (parseInt(item.mnum) % 2 ? [" (Half Time)"] : [" (Full Time)"]) : null}
          </span>
        </td>
        <td style={{ width: "12%", textAlign: "center", backgroundColor: item.mnum % 2 === 0 ? "#fafafa" : "#f2f2f2" }} rowSpan={2}>
          <span style={pick_result_colour(item.home_score, item.away_score, item.match_status)}>
            {item.match_status === "Not Started" ? "" : item.home_score + " - " + item.away_score}
          </span>
        </td>
        <td style={{ width: "34%", textAlign: "right", backgroundColor: item.mnum % 2 === 0 ? "#fafafa" : "#f2f2f2" }} rowSpan={2}>
          <span style={pick_away_team_colour(item.home_score, item.away_score, item.match_status)}>
            {item.teamB}
            {pool_title.startsWith("S8 ") ? (parseInt(item.mnum) % 2 ? [" (Half Time)"] : [" (Full Time)"]) : null} <br />
          </span>
        </td>
      </tr>
      <tr>
        <td
          colspan={3}
          style={{ fontSize: "0.65em", paddingTop: 0, paddingBottom: 0, textAlign: "center", backgroundColor: item.mnum % 2 === 0 ? "#fafafa" : "#f2f2f2" }}
        >
          {item.comp_name}
        </td>
      </tr>
    </>
  );
};

export default LiveMatchSummaryWide;
