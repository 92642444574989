import React, { useEffect, useState } from "react";
import ArticleElement from "./ArticleElement";

const ArticlesElement = () => {
  const [PositionedArticles, setPositionedArticles] = useState([]);

  const loadArticles = async () => {
    const positioned_articles = [];
    const json_articles = await (await fetch(`/api/GetArticles`)).json();
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    for (const article of json_articles) {
      let d = new Date(article.RowKey.substring(0, 10));
      let updated_article = {
        date: weekday[d.getDay()] + " " + d.getDate() + " " + monthName[d.getMonth()],
        title: article.title,
        author: article.author,
        strap_line: article.strap_line,
        raw_date_id: article.RowKey,
      };
      updated_article["category"] = article.PartitionKey;
      if (article.PartitionKey === "Soccer10OfTheDay") {
        updated_article["header"] = "SOCCER 10 OF THE DAY";
      } else if (article.PartitionKey === "Soccer6OfTheDay") {
        updated_article["header"] = "SOCCER 6 OF THE DAY";
      } else if (article.PartitionKey === "Soccer4OfTheDay") {
        updated_article["header"] = "SOCCER 4 OF THE DAY";
      } else if (article.PartitionKey === "Soccer13OfTheDay") {
        updated_article["header"] = "SOCCER 13 OF WEEK";
      } else if (article.PartitionKey === "Soccer13XOfTheDay") {
        updated_article["header"] = "SOCCER ANY 13 XTRA";
      } else if (article.PartitionKey === "Mega10OfTheDay") {
        updated_article["header"] = "MEGA 10 JACKPOT";
      } else if (article.PartitionKey === "StoryOfWeek") {
        updated_article["header"] = "STORY OF THE WEEK";
      } else if (article.PartitionKey === "Briefs") {
        updated_article["header"] = "BRIEFS";
      } else if (article.PartitionKey === "Tip") {
        updated_article["header"] = "WHAT THEY SAID";
      } else if (article.PartitionKey === "Soccer10Payout") {
        updated_article["header"] = "SOCCER 10 PAYOUT";
      } else if (article.PartitionKey === "Soccer13Payout") {
        updated_article["header"] = "SOCCER 13 PAYOUT";
      } else if (article.PartitionKey === "Soccer6Payout") {
        updated_article["header"] = "SOCCER 6 PAYOUT";
      } else if (article.PartitionKey === "Column") {
        updated_article["header"] = "COLUMN";
      } else if (article.PartitionKey === "Preview") {
        updated_article["header"] = "PREVIEW";
      }
      positioned_articles.push(updated_article);
    }
    setPositionedArticles(positioned_articles);
  };

  useEffect(() => {
    (async () => {
      loadArticles();
    })();
  }, []);

  return (
    <div>
      <div className="row fifth">
        <div className="large-4 columns">
          {PositionedArticles.map((article) => (
            <ArticleElement article={article} />
          ))}
          <div className="divider"></div>
        </div>
      </div>
    </div>
  );
};

export default ArticlesElement;
