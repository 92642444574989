import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const getPrettyDate = (programDate) => {
  let d = new Date(programDate);
  return weekday[d.getDay()] + " " + d.getDate() + " " + monthName[d.getMonth()];
};

const PreviewsByPool = () => {
  const [S10Pools, setS10Pools] = useState([]);
  const [S6Pools, setS6Pools] = useState([]);
  const [S13Pools, setS13Pools] = useState([]);
  const [S4Pools, setS4Pools] = useState([]);
  const [S13XPools, setS13XPools] = useState([]);
  const [S1Pools, setS1Pools] = useState([]);
  const [SSPools, setSSPools] = useState([]);
  const [SCPools, setSCPools] = useState([]);
  const [ContentLoaded, setContentLoaded] = useState(false);
  const location = useLocation();
  const linkStyle = {
    color: "#001489",
    background: "none",
    border: "none",
    padding: 0,
    fontSize: "0.85em",
    fontWeight: "bold",
    cursor: "pointer",
    textDecoration: "none",
  };

  useEffect(() => {
    (async () => {
      const loadPools = async () => {
        let loaded_pools = await (await fetch(`/api/GetSortedPools`)).json();
        const temp_10 = [],
          temp_6 = [],
          temp_13 = [],
          temp_4 = [],
          temp_13x = [],
          temp_1 = [],
          temp_s = [],
          temp_c = [];
        for (const pool of loaded_pools) {
          if (pool.PartitionKey.startsWith("S10 ")) {
            temp_10.push(pool);
          } else if (pool.PartitionKey.startsWith("S6 ")) {
            temp_6.push(pool);
          } else if (pool.PartitionKey.startsWith("S13 ")) {
            temp_13.push(pool);
          } else if (pool.PartitionKey.startsWith("S4 ")) {
            temp_4.push(pool);
          } else if (pool.PartitionKey.startsWith("S13X ")) {
            temp_13x.push(pool);
          } else if (pool.PartitionKey.startsWith("S1 ")) {
            temp_1.push(pool);
          } else if (pool.PartitionKey.startsWith("SS ")) {
            temp_s.push(pool);
          } else if (pool.PartitionKey.startsWith("SC ")) {
            temp_c.push(pool);
          }
        }
        setS10Pools(
          temp_10.sort((a, b) => {
            if (a.RowKey < b.RowKey) return -1;
            if (a.RowKey > b.RowKey) return 1;
            return 0;
          })
        );
        setS6Pools(
          temp_6.sort((a, b) => {
            if (a.RowKey < b.RowKey) return -1;
            if (a.RowKey > b.RowKey) return 1;
            return 0;
          })
        );
        setS13Pools(
          temp_13.sort((a, b) => {
            if (a.RowKey < b.RowKey) return -1;
            if (a.RowKey > b.RowKey) return 1;
            return 0;
          })
        );
        setS4Pools(
          temp_4.sort((a, b) => {
            if (a.RowKey < b.RowKey) return -1;
            if (a.RowKey > b.RowKey) return 1;
            return 0;
          })
        );
        setS13XPools(
          temp_13x.sort((a, b) => {
            if (a.RowKey < b.RowKey) return -1;
            if (a.RowKey > b.RowKey) return 1;
            return 0;
          })
        );
        setS1Pools(
          temp_1.sort((a, b) => {
            if (a.RowKey < b.RowKey) return -1;
            if (a.RowKey > b.RowKey) return 1;
            return 0;
          })
        );
        setSSPools(
          temp_s.sort((a, b) => {
            if (a.RowKey < b.RowKey) return -1;
            if (a.RowKey > b.RowKey) return 1;
            return 0;
          })
        );
        setSCPools(
          temp_c.sort((a, b) => {
            if (a.RowKey < b.RowKey) return -1;
            if (a.RowKey > b.RowKey) return 1;
            return 0;
          })
        );
      };
      if (S10Pools.length === 0) {
        await loadPools();
        setContentLoaded(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      // Check if there's a hash in the URL
      if (ContentLoaded && location.hash) {
        const id = location.hash.substring(1);
        // Find the element with the id
        const element = document.getElementById(id);
        // If found, scroll to it
        if (element) {
          setTimeout(() => {
            document.body.style.overflow = "visible";
            element.scrollIntoView({ behavior: "smooth" });
          }, 0);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContentLoaded, location]);

  return (
    <div className="row second">
      <div className="large-12 columns">
        <h1>Match Previews - By Pool</h1>
        <p>
          <a
            href="https://www.soccer6.co.za/form-guides/previews"
            style={{
              color: "#001489",
              fontWeight: "bold",
            }}
          >
            Match Previews - Alphabetical / By Kickoff
          </a>
        </p>
        <section id="Soccer10">
          <h4>Soccer 10 Previews</h4>
          <br />
          <button onClick={() => document.getElementById("Soccer6").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 6
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 13
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer4").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 4
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13X").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Any 13 Xtra
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer1").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 1
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerScores").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Scores
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerCorners").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Corners
          </button>
          <br />
          {S10Pools.map((item) => (
            <div key={item.PartitionKey}>
              <section id={item.programDate + "_" + item.programCode + "_" + item.race}>
                <div className="pooldateindicator4R">
                  {getPrettyDate(item.programDate)} - {item.PartitionKey}
                </div>
                {item.matches &&
                  item.matches.map((match, index) => (
                    <p>
                      M{index + 1} {match.RowKey}: {match.preview ?? ""}
                    </p>
                  ))}
              </section>
            </div>
          ))}
        </section>
        <section id="Soccer6">
          <h4>Soccer 6 Previews</h4>
          <br />
          <button onClick={() => document.getElementById("Soccer10").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 10
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 13
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer4").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 4
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13X").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Any 13 Xtra
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer1").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 1
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerScores").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Scores
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerCorners").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Corners
          </button>
          <br />
          {S6Pools.map((item) => (
            <div key={item.PartitionKey}>
              <section id={item.programDate + "_" + item.programCode + "_" + item.race}>
                <div className="pooldateindicator4R">
                  {getPrettyDate(item.programDate)} - {item.PartitionKey}
                </div>
                {item.matches &&
                  item.matches.map((match, index) => (
                    <p>
                      M{index + 1} {match.RowKey}: {match.preview ?? ""}
                    </p>
                  ))}
              </section>
            </div>
          ))}
        </section>
        <section id="Soccer13">
          <h4>Soccer 13 Previews</h4>
          <br />
          <button onClick={() => document.getElementById("Soccer10").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 10
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer6").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 6
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer4").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 4
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13X").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Any 13 Xtra
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer1").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 1
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerScores").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Scores
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerCorners").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Corners
          </button>
          <br />
          {S13Pools.map((item) => (
            <div key={item.PartitionKey}>
              <section id={item.programDate + "_" + item.programCode + "_" + item.race}>
                <div className="pooldateindicator4R">
                  {getPrettyDate(item.programDate)} - {item.PartitionKey}
                </div>
                {item.matches &&
                  item.matches.map((match, index) => (
                    <p>
                      M{index + 1} {match.RowKey}: {match.preview ?? ""}
                    </p>
                  ))}
              </section>
            </div>
          ))}
        </section>
        <section id="Soccer4">
          <h4>Soccer 4 Previews</h4>
          <br />
          <button onClick={() => document.getElementById("Soccer10").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 10
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer6").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 6
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 13
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13X").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Any 13 Xtra
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer1").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 1
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerScores").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Scores
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerCorners").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Corners
          </button>
          <br />
          {S4Pools.map((item) => (
            <div key={item.PartitionKey}>
              <section id={item.programDate + "_" + item.programCode + "_" + item.race}>
                <div className="pooldateindicator4R">
                  {getPrettyDate(item.programDate)} - {item.PartitionKey}
                </div>
                {item.matches &&
                  item.matches.map((match, index) => (
                    <p>
                      M{index + 1} {match.RowKey}: {match.preview ?? ""}
                    </p>
                  ))}
              </section>
            </div>
          ))}
        </section>
        <section id="Soccer13X">
          <h4>Soccer Any 13 Xtra Previews</h4>
          <br />
          <button onClick={() => document.getElementById("Soccer10").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 10
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer6").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 6
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 13
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer4").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 4
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer1").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 1
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerScores").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Scores
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerCorners").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Corners
          </button>
          <br />
          {S13XPools.map((item) => (
            <div key={item.PartitionKey}>
              <section id={item.programDate + "_" + item.programCode + "_" + item.race}>
                <div className="pooldateindicator4R">
                  {getPrettyDate(item.programDate)} - {item.PartitionKey}
                </div>
                {item.matches &&
                  item.matches.map((match, index) => (
                    <p>
                      M{index + 1} {match.RowKey}: {match.preview ?? ""}
                    </p>
                  ))}
              </section>
            </div>
          ))}
        </section>
        <section id="Soccer1">
          <h4>Soccer 1 Previews</h4>
          <br />
          <button onClick={() => document.getElementById("Soccer10").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 10
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer6").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 6
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 13
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer4").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 4
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13X").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Any 13 Xtra
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerScores").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Scores
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerCorners").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Corners
          </button>
          <br />
          {S1Pools.map((item) => (
            <div key={item.PartitionKey}>
              <section id={item.programDate + "_" + item.programCode + "_" + item.race}>
                <div className="pooldateindicator4R">
                  {getPrettyDate(item.programDate)} - {item.PartitionKey}
                </div>
                {item.matches &&
                  item.matches.map((match, index) => (
                    <p>
                      M{index + 1} {match.RowKey}: {match.preview ?? ""}
                    </p>
                  ))}
              </section>
            </div>
          ))}
        </section>
        <section id="SoccerScores">
          <h4>Soccer Scores Previews</h4>
          <br />
          <button onClick={() => document.getElementById("Soccer10").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 10
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer6").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 6
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 13
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer4").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 4
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13X").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Any 13 Xtra
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer1").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 1
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerCorners").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Corners
          </button>
          <br />
          {SSPools.map((item) => (
            <div key={item.PartitionKey}>
              <section id={item.programDate + "_" + item.programCode + "_" + item.race}>
                <div className="pooldateindicator4R">
                  {getPrettyDate(item.programDate)} - {item.PartitionKey}
                </div>
                {item.matches &&
                  item.matches.map((match, index) => (
                    <p>
                      M{index + 1} {match.RowKey}: {match.preview ?? ""}
                    </p>
                  ))}
              </section>
            </div>
          ))}
        </section>
        <section id="SoccerCorners">
          <h4>Soccer Corners Previews</h4>
          <br />
          <button onClick={() => document.getElementById("Soccer10").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 10
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer6").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 6
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 13
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer4").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 4
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer13X").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Any 13 Xtra
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("Soccer1").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer 1
          </button>
          &nbsp;|&nbsp;
          <button onClick={() => document.getElementById("SoccerScores").scrollIntoView({ behavior: "smooth" })} style={linkStyle}>
            Soccer Scores
          </button>
          <br />
          {SCPools.map((item) => (
            <div key={item.PartitionKey}>
              <section id={item.programDate + "_" + item.programCode + "_" + item.race}>
                <div className="pooldateindicator4R">
                  {getPrettyDate(item.programDate)} - {item.PartitionKey}
                </div>
                {item.matches &&
                  item.matches.map((match, index) => (
                    <p>
                      M{index + 1} {match.RowKey}: {match.preview ?? ""}
                    </p>
                  ))}
              </section>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default PreviewsByPool;
