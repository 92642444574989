import React, { useEffect, useState } from "react";
import PoolItemWideDemo from "./PoolItemWideDemo";
import PoolItemWideLoaderLarge from "./PoolItemWideLoaderLarge";

const PoolPageDemo = ({ pool_id }) => {
  const [Pools, setPools] = useState([]);
  const [PageName, setPageName] = useState("");
  const [PoolVolume, setPoolVolume] = useState(-1);

  useEffect(() => {
    (async () => {
      const loadPools = async () => {
        let start_term = "";
        let end_term = "";
        let page_name = "";
        switch (pool_id) {
          case 6:
            start_term = "S6 ";
            end_term = "S7 ";
            page_name = "Pools and Matches - Soccer 6";
            break;
          case 10:
            start_term = "S10 ";
            end_term = "S11 ";
            page_name = "Pools and Matches - Soccer 10";
            break;
          case 13:
            start_term = "S13 ";
            end_term = "S13A ";
            page_name = "Pools and Matches - Soccer 13";
            break;
          case 4:
            start_term = "S4 ";
            end_term = "S5 ";
            page_name = "Pools and Matches - Soccer 4";
            break;
          case 16:
            start_term = "S13X ";
            end_term = "S13Y ";
            page_name = "Pools and Matches - Soccer Any 13 Xtra";
            break;
          case 12:
            start_term = "Megastrike ";
            end_term = "Megastrikf ";
            page_name = "Pools and Matches - MegaStrike 10";
            break;
          case 1:
            start_term = "S1 V";
            end_term = "S10 ";
            page_name = "Pools and Matches - Soccer 1";
            break;
          case 8:
            start_term = "S8 ";
            end_term = "S9 ";
            page_name = "Pools and Matches - Soccer 8 HF";
            break;
          case 5:
            start_term = "SS ";
            end_term = "ST ";
            page_name = "Pools and Matches - Soccer Scores";
            break;
          case 7:
            start_term = "SC ";
            end_term = "SD ";
            page_name = "Pools and Matches - Soccer Corners";
            break;
          case 20:
            start_term = "_";
            end_term = "_";
            page_name = "Free2Play Pools";
            break;
          default:
            start_term = "Error ";
            end_term = "Error ";
            page_name = "Error ";
        }
        const pools = (await (await fetch(`/api/GetFullPools/${start_term}/${end_term}`)).json()).value;
        const temp = [];
        for (const pool of pools) {
          temp.push(pool);
        }
        temp.sort((a, b) => (a.RowKey > b.RowKey ? 1 : -1));
        setPools(temp);
        setPageName(page_name);
        setPoolVolume(temp.length);
      };
      loadPools();
    })();
  }, [pool_id]);

  return (
    <div className="row second">
      <div className="large-12 columns">
        <h1>{PageName}</h1>
        {PoolVolume === -1 ? <PoolItemWideLoaderLarge /> : PoolVolume === 0 ? <div className="panel">No pool currently open.</div> : ""}
        {Pools.map((item) => (
          <PoolItemWideDemo item={item} />
        ))}
      </div>
    </div>
  );
};

export default PoolPageDemo;
