import React from "react";

const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const getPrettyDate = (programDate) => {
  let d = new Date(programDate);
  return weekday[d.getDay()] + " " + d.getDate() + " " + monthName[d.getMonth()];
};
const format_payout_commas = (input) => {
  return input.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};
const format_decimal_integer = (input) => {
  return input.replace(/(\d+).\d{2}/g, "$1");
};

const S5Result = ({ item }) => {
  return (
    <div>
      <div className="pooldateindicator4R">
        {getPrettyDate(item.RowKey)} - SS {item.league_title} ({item.program_code})
      </div>
      <table className="pooltable">
        <tr>
          <th>Match</th>
          <th>Team A</th>
          <th>Score</th>
          <th>Team B</th>
        </tr>
        <tr>
          <td className="bold">M1</td>
          <td>{item.match1TeamA}</td>
          <td>{item.match1Result}</td>
          <td className="righttext">{item.match1TeamB}</td>
        </tr>
        <tr>
          <td className="bold">M2</td>
          <td>{item.match2TeamA}</td>
          <td>{item.match2Result}</td>
          <td className="righttext">{item.match2TeamB}</td>
        </tr>
        <tr>
          <td className="bold">M3</td>
          <td>{item.match3TeamA}</td>
          <td>{item.match3Result}</td>
          <td className="righttext">{item.match3TeamB}</td>
        </tr>
        <tr>
          <td className="bold">M4</td>
          <td>{item.match4TeamA}</td>
          <td>{item.match4Result}</td>
          <td className="righttext">{item.match4TeamB}</td>
        </tr>
        <tr>
          <td className="bold">M5</td>
          <td>{item.match5TeamA}</td>
          <td>{item.match5Result}</td>
          <td className="righttext">{item.match5TeamB}</td>
        </tr>
        <tr>
          <td className="bold">M6</td>
          <td>{item.match6TeamA}</td>
          <td>{item.match6Result}</td>
          <td className="righttext">{item.match6TeamB}</td>
        </tr>
      </table>
      <div className="panel poolinformation">
        Winning Combination: {item.combination}
        <br />
        Status: Official
        <br />
        <br />
        {item.tickets1_6 ? (
          <div>
            Results: (1/6)
            <br />
            Net 1/6: R{format_payout_commas(item.nett1_6)}
            <br />
            Winners: {format_decimal_integer(item.tickets1_6)}
            <br />
            Dividend: R{format_payout_commas(item.value1_6)}
            <br />
            <br />
          </div>
        ) : null}
        {item.tickets2_6 ? (
          <div>
            Results: (2/6)
            <br />
            Net 2/6: R{format_payout_commas(item.nett2_6)}
            <br />
            Winners: {format_decimal_integer(item.tickets2_6)}
            <br />
            Dividend: R{format_payout_commas(item.value2_6)}
            <br />
            <br />
          </div>
        ) : null}
        {item.tickets3_6 ? (
          <div>
            Results: (3/6)
            <br />
            Net 3/6: R{format_payout_commas(item.nett3_6)}
            <br />
            Winners: {format_decimal_integer(item.tickets3_6)}
            <br />
            Dividend: R{format_payout_commas(item.value3_6)}
            <br />
            <br />
          </div>
        ) : null}
        {item.tickets4_6 ? (
          <div>
            Results: (4/6)
            <br />
            Net 4/6: R{format_payout_commas(item.nett4_6)}
            <br />
            Winners: {format_decimal_integer(item.tickets4_6)}
            <br />
            Dividend: R{format_payout_commas(item.value4_6)}
            <br />
            <br />
          </div>
        ) : null}
        {item.tickets5_6 ? (
          <div>
            Results: (5/6)
            <br />
            Net 5/6: R{format_payout_commas(item.nett5_6)}
            <br />
            Winners: {format_decimal_integer(item.tickets5_6)}
            <br />
            Dividend: R{format_payout_commas(item.value5_6)}
            <br />
            <br />
          </div>
        ) : null}
        Results: (6/6)
        <br />
        Net 6/6: R{format_payout_commas(item.nett6_6)}
        <br />
        Winners: {format_decimal_integer(item.tickets6_6)}
        <br />
        Dividend: R{format_payout_commas(item.value6_6)}
        <br />
        <br />
      </div>
    </div>
  );
};

export default S5Result;
