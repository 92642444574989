import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import S6Result from "./S6Result";
import S10Result from "./S10Result";
import S13Result from "./S13Result";
import S4Result from "./S4Result";
import S13XResult from "./S13XResult";
import MegastrikeResult from "./MegastrikeResult";
import S1Result from "./S1Result";
import S8Result from "./S8Result";
import S2Result from "./S2Result";
import S15Result from "./S15Result";
import S5Result from "./S5Result";
import S7Result from "./S5Result";
import PoolItemWideLoaderLarge from "./PoolItemWideLoaderLarge";

const ArchivedResults = () => {
  const [Results, setResults] = useState([]);
  const [PageName, setPageName] = useState("");
  const { id, subdate } = useParams();

  const renderResultType = (item) => {
    switch (id) {
      case "6":
        return <S6Result item={item} />;
      case "10":
        return <S10Result item={item} />;
      case "13":
        return <S13Result item={item} />;
      case "4":
        return <S4Result item={item} />;
      case "16":
        return <S13XResult item={item} />;
      case "12":
        return <MegastrikeResult item={item} />;
      case "1":
        return <S1Result item={item} />;
      case "8":
        return <S8Result item={item} />;
      case "2":
        return <S2Result item={item} />;
      case "15":
        return <S15Result item={item} />;
      case "5":
        return <S5Result item={item} />;
      case "7":
        return <S7Result item={item} />;
      default:
        return null;
    }
  };

  const renderPrettyDate = (subdate) => {
    const month_part = subdate.slice(-3);
    const year_part = subdate.substring(0, 4);
    switch (month_part) {
      case "-01":
        return "January " + year_part;
      case "-02":
        return "February " + year_part;
      case "-03":
        return "March " + year_part;
      case "-04":
        return "April " + year_part;
      case "-05":
        return "May " + year_part;
      case "-06":
        return "June " + year_part;
      case "-07":
        return "July " + year_part;
      case "-08":
        return "August " + year_part;
      case "-09":
        return "September " + year_part;
      case "-10":
        return "October " + year_part;
      case "-11":
        return "November " + year_part;
      case "-12":
        return "December " + year_part;
      default:
        return null;
    }
  };

  useEffect(() => {
    (async () => {
      const loadArchivedResults = async () => {
        let page_name = "";
        switch (id) {
          case "6":
            page_name = "Soccer 6 Archived Results: " + renderPrettyDate(subdate);
            break;
          case "10":
            page_name = "Soccer 10 Archived Results: " + renderPrettyDate(subdate);
            break;
          case "13":
            page_name = "Soccer 13 Archived Results: " + renderPrettyDate(subdate);
            break;
          case "4":
            page_name = "Soccer 4 Archived Results: " + renderPrettyDate(subdate);
            break;
          case "16":
            page_name = "Soccer Any 13 Xtra Archived Results: " + renderPrettyDate(subdate);
            break;
          case "12":
            page_name = "Megastrike 10 Archived Results: " + renderPrettyDate(subdate);
            break;
          case "1":
            page_name = "Soccer 1 Archived Results: " + renderPrettyDate(subdate);
            break;
          case "8":
            page_name = "Soccer 8 HF Archived Results: " + renderPrettyDate(subdate);
            break;
          case "2":
            page_name = "Soccer 2 Archived Results: " + renderPrettyDate(subdate);
            break;
          case "15":
            page_name = "Soccer Any 15 Archived Results: " + renderPrettyDate(subdate);
            break;
          case "5":
            page_name = "Soccer Scores Archived Results: " + renderPrettyDate(subdate);
            break;
          case "7":
            page_name = "Soccer Corners Archived Results: " + renderPrettyDate(subdate);
            break;
          default:
            page_name = "Error " + id;
        }
        const results = (await (await fetch(`/api/GetArchivedResults/${id}/${subdate}`)).json()).value;
        const temp = [];
        for (const result of results) {
          temp.push(result);
        }
        // temp.reverse(); dont reverse archives
        setResults(temp);
        setPageName(page_name);
      };
      loadArchivedResults();
    })();
  }, [id, subdate]);

  return (
    <div className="row second">
      <div className="large-12 columns">
        {Results.length > 0 ? (
          <>
            <h1>{PageName}</h1>
            {Results.map((item) => renderResultType(item))}
          </>
        ) : (
          <PoolItemWideLoaderLarge />
        )}
      </div>
    </div>
  );
};

export default ArchivedResults;
