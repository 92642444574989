import React, { useEffect, useState } from "react";
import LivePoolItemWide from "./LivePoolItemWide";
import PoolItemWideLoaderLarge from "./PoolItemWideLoaderLarge";

const LivePageYesterday = () => {
  const [Pools, setPools] = useState([]);
  const [PageName, setPageName] = useState("");
  const [PoolVolume, setPoolVolume] = useState(-1);
  const [UtcHour, setUtcHour] = useState(0);

  const parse_number_pool_size = (poolTotal) => {
    return parseInt(poolTotal.replace(/R(.*?)\.00/g, "$1").replace(/,/g, ""));
  };

  useEffect(() => {
    (async () => {
      const loadPools = async () => {
        const now = new Date();
        now.setUTCDate(now.getUTCDate() - 1);
        setUtcHour(now.getUTCHours());
        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, "0");
        const day = String(now.getUTCDate()).padStart(2, "0");
        const weekdays = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const weekday = weekdays[now.getUTCDay()];
        const monthName = months[now.getUTCMonth()];
        const pools = (await (await fetch(`/api/GetLivePools/${year}-${month}-${day}`)).json()).value;
        const temp = [];
        for (const pool of pools) {
          temp.push(pool);
        }
        temp.sort((a, b) => (parse_number_pool_size(a.poolTotal) < parse_number_pool_size(b.poolTotal) ? 1 : -1));
        setPools(temp);
        setPageName(`Live Pool Projections: ${weekday} ${now.getUTCDate()} ${monthName}`);
        setPoolVolume(temp.length);
      };
      loadPools();
      const intervalId = setInterval(() => {
        loadPools(); // Fetch data every 2 min
      }, 120000);
      return () => clearInterval(intervalId);
    })();
  }, []);

  return (
    <div className="row second">
      <div className="large-12 columns">
        <h1>{PageName}</h1>
        {UtcHour > 2 ? (
          <p>
            <a href="/live-pool-projections" style={{ color: "grey", fontWeight: "bold" }}>
              Show Today's Pool Projections
            </a>
          </p>
        ) : null}
        <br />
        {PoolVolume === -1 ? <PoolItemWideLoaderLarge /> : PoolVolume === 0 ? <div className="panel">No pool currently open.</div> : ""}
        {Pools.map((item) => (
          <>
            <LivePoolItemWide item={item} />
          </>
        ))}
      </div>
    </div>
  );
};

export default LivePageYesterday;
