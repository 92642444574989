import React from "react";

const S8Howto = () => {
  return (
    <div className="row second">
      <div className="large-12 columns">
        <h1>How To Play - Soccer 8 HF</h1>
        <blockquote>
          <p>Play TAB's fantastic new bet Soccer 8 HF! Here are the details:</p>
        </blockquote>
        <p>
          <b>* 4 matches - predict the halftime and fulltime results of all 4 games to win! This is how each Soccer 8 HF will be displayed:</b>
        </p>
        <p>M1H Team A vs Team B = forecast your halftime result choice/s</p>
        <p>M2F Team A vs Team B = forecast your fulltime result choice/s</p>
        <p>M3H Team C vs Team D = forecast your halftime result choice/s</p>
        <p>M4F Team C vs Team D = forecast your fulltime result choice/s</p>
        <p>M5H Team E vs Team F = forecast your halftime result choice/s</p>
        <p>M6F Team E vs Team F = forecast your fulltime result choice/s</p>
        <p>M7H Team G vs Team H = forecast your halftime result choice/s</p>
        <p>M8F Team G vs Team H = forecast your fulltime result choice/s</p>

        <p>
          <b>* 3 result choices for halftime and fulltime in each match:</b>
        </p>
        <p>1 = Win by Team A</p>

        <p>2 = Draw</p>

        <p>3 = Win by Team B</p>

        <ul>
          <li>
            Play a single entry for R3 (choose one result option for halftime and one for fulltime per match). Or increase your chance of winning with a
            multiple or permutation entry. With a multiple entry you choose as many result options as you wish for halftime and fulltime in all the matches. The
            cost is R3 per combination. Example: You select all three result choices for halftime in M1H and for fulltime in M1F, and then one result choice for
            halftime and fulltime in all the other games. That'’'s nine combinations (3x3x1x1x1x1x1x1= 9) at a cost of R3 per combination = 9 x R3 = R27.
          </li>
          <li>
            In TABs Soccer 8 HF must be called to the teller. How to call the bet is clearly displayed with all the relevant pools. As an example, call Soccer 8
            HF as follows to the teller: “Soccer 8 HF, Venue 1” and then your result choices for each match: “M1 numbers 1, 2 and 3; M2 number 1; M3 1 and 2”
            etc
          </li>
          <li>
            How much you win depends on the number of winning tickets and the total amount of money in the pool. As with all TAB soccer pools, the payout is
            calculated by dividing the number of winning tickets into the total net pool.
          </li>
          <li>
            The results of the match are the score at halftime and then the score at the end of the normal 90-minute playing period, plus any injury
            time/referee's optional time that may be played. Extra time and penalty shootouts do not count.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default S8Howto;
