import React from "react";

const InfoArchive = () => {
  return (
    <div>
      <a className="button expand" style={{ backgroundColor: "#001489", border: "1px solid #00b74f" }} href="/archive/soccer-1">
        Soccer 1
      </a>
      <a className="button expand" style={{ backgroundColor: "#001489", border: "1px solid #00b74f" }} href="/archive/soccer-2">
        Soccer 2
      </a>
      <a className="button expand" style={{ backgroundColor: "#001489", border: "1px solid #00b74f" }} href="/archive/soccer-4">
        Soccer 4
      </a>
      <a className="button expand" style={{ backgroundColor: "#001489", border: "1px solid #00b74f" }} href="/archive/soccer-6">
        Soccer 6
      </a>
      <a className="button expand" style={{ backgroundColor: "#001489", border: "1px solid #00b74f" }} href="/archive/soccer-10">
        Soccer 10
      </a>
      <a className="button expand" style={{ backgroundColor: "#001489", border: "1px solid #00b74f" }} href="/archive/megastrike">
        MegaStrike 10
      </a>
      <a className="button expand" style={{ backgroundColor: "#001489", border: "1px solid #00b74f" }} href="/archive/soccer-13">
        Soccer 13
      </a>
      <a className="button expand" style={{ backgroundColor: "#001489", border: "1px solid #00b74f" }} href="/archive/soccer-13X">
        Soccer 13 Xtra
      </a>
      <a className="button expand" style={{ backgroundColor: "#001489", border: "1px solid #00b74f" }} href="/archive/soccer-15">
        Soccer Any 15
      </a>
      <a className="button expand" style={{ backgroundColor: "#001489", border: "1px solid #00b74f" }} href="/archive/soccer-5">
        Soccer Scores
      </a>
      <a className="button expand" style={{ backgroundColor: "#001489", border: "1px solid #00b74f" }} href="/archive/soccer-7">
        Soccer Corners
      </a>
    </div>
  );
};

export default InfoArchive;
