import React from "react";

const S7Howto = () => {
  return (
    <div className="row second">
      <div className="large-12 columns">

        <blockquote>Predict the total number of corners in each of six games choosing from five options.</blockquote>
        <p>There are 5 result options in each match in the pool:</p>
        <p>The computerised tote betting system uses numbers to denote the following options:</p>

        <div className="panel">
          <ul>
            <li>1 = 0-7 corners in the match</li>
            <li>2 = 8 corners in the match</li>
            <li>3 = 9 corners in the match</li>
            <li>4 = 10 corners in the match</li>
            <li>5 = 11 or more corners in the match</li>
          </ul>
          <p>So when you bet, use numbers 1 to 5 to denote your choices. The same numbers are used to display results.</p>
        </div>
        <p>
          <strong>PLEASE NOTE: </strong>In Soccer Corners and other TAB soccer bets, the result of a match is the score at the end of the normal 90-minute playing
          period plus any injury time that may beplayed. Extra time and penalty shoot-outs are of no account.
        </p>
        <h2>What Does Soccer Corners Cost</h2>
        <p>The unit of betting is R2.</p>
      </div>
    </div>
  );
};

export default S7Howto;
