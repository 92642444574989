import React from "react";
import ContentLoader from "react-content-loader";

const PoolTabsLoader = (props) => (
  <ContentLoader speed={2} width={530} height={60} viewBox="0 0 530 60" backgroundColor="#d7e5d7" foregroundColor="#ecebeb" {...props}>
    <rect x="10" y="1" rx="0" ry="0" width="58" height="58" /> 
    <rect x="70" y="1" rx="0" ry="0" width="58" height="58" />
    <rect x="130" y="1" rx="0" ry="0" width="58" height="58" />
    <rect x="190" y="1" rx="0" ry="0" width="58" height="58" />
    <rect x="250" y="1" rx="0" ry="0" width="58" height="58" />
    <rect x="310" y="1" rx="0" ry="0" width="58" height="58" />
    <rect x="370" y="1" rx="0" ry="0" width="58" height="58" />
    <rect x="430" y="1" rx="0" ry="0" width="58" height="58" />
  </ContentLoader>
);

export default PoolTabsLoader;
