import React from "react";
import ArticleLoader from "./ArticleLoader";

const ArticleElement = ({ article }) => {
  return (
    <>
      <div className="sectionheader">{article.header}</div>
      {article.strap_line ? (
        <div className="newsitem">
          <h3>{article.title}</h3>
          <p className="newsdate">
            {article.date} by {article.author}
          </p>
          <img className="newsimage" src="/images/goal.png" alt="News" />
          <p className="newsexcerpt">{article.strap_line}</p>
          <a className="button button4R tiny readmorelink" href={"/newsitem/" + article.category + "/" + article.raw_date_id}>
            Read More
          </a>
        </div>
      ) : (
        <div className="newsitem">
          <ArticleLoader />
        </div>
      )}
    </>
  );
};

export default ArticleElement;
