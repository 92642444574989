import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LivePoolItemWide from "./LivePoolItemWide";
import PoolItemWideLoaderLarge from "./PoolItemWideLoaderLarge";

const LivePageYesterday = () => {
  const [Pools, setPools] = useState([]);
  const [PageName, setPageName] = useState("");
  const [PoolVolume, setPoolVolume] = useState(-1);
  const [UtcHour, setUtcHour] = useState(0);
  const [ContentLoaded, setContentLoaded] = useState(false);
  const location = useLocation();

  const parse_number_pool_size = (poolTotal) => {
    return parseInt(poolTotal.replace(/R(.*?)\.00/g, "$1").replace(/,/g, ""));
  };

  const scrollToHash = useCallback(() => {
    if (location.hash) {
      const id = location.hash.substring(1);
      // console.log('Trying to scroll to:', id);
      const element = document.getElementById(id);
      if (element) {
        // console.log('Element found, scrolling...');
        element.scrollIntoView({ behavior: "smooth" });
        return true;
      } else {
        // console.log('Element not found');
        return false;
      }
    }
    return true;
  }, [location.hash]);

  useEffect(() => {
    (async () => {
      const loadPools = async () => {
        // if time now is before 7 am show yesterdays date, otherwise today
        const now = new Date();
        let temp_utc_hour = now.getUTCHours();
        setUtcHour(temp_utc_hour);
        // If it's before 5 AM UTC, we'll use yesterday's date
        if (temp_utc_hour < 5) {
          now.setUTCDate(now.getUTCDate() - 1);
        }
        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, "0");
        const day = String(now.getUTCDate()).padStart(2, "0");
        const weekdays = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const weekday = weekdays[now.getUTCDay()];
        const monthName = months[now.getUTCMonth()];
        const pools = (await (await fetch(`/api/GetLivePools/${year}-${month}-${day}`)).json()).value;
        const temp = [];
        for (const pool of pools) {
          temp.push(pool);
        }
        temp.sort((a, b) => (parse_number_pool_size(a.poolTotal) < parse_number_pool_size(b.poolTotal) ? 1 : -1));
        setPools(temp);
        setPageName(`Live Pool Projections: ${weekday} ${now.getUTCDate()} ${monthName}`);
        setPoolVolume(temp.length);
      };
      loadPools();
      setContentLoaded(true);
      const intervalId = setInterval(() => {
        loadPools(); // Fetch data every 2 min
      }, 120000);
      return () => clearInterval(intervalId);
    })();
  }, []);

  useEffect(() => {
    if (ContentLoaded) {
      // console.log('Content loaded, attempting to scroll');
      let attempts = 0;
      const maxAttempts = 10;
      const attemptInterval = 500; // 500ms between attempts

      const attemptScroll = () => {
        if (scrollToHash()) {
          // console.log('Scroll successful');
        } else if (attempts < maxAttempts) {
          attempts++;
          // console.log(`Attempt ${attempts} failed, trying again in ${attemptInterval}ms`);
          setTimeout(attemptScroll, attemptInterval);
        } else {
          // console.log('Max attempts reached, scroll failed');
        }
      };

      attemptScroll();
    }
  }, [ContentLoaded, scrollToHash]);

  return (
    <div className="row second">
      <div className="large-12 columns">
        <h1>{PageName}</h1>
        {UtcHour >= 8 ? (
          <p>
            <a href="/live-pool-projections-1" style={{ color: "grey", fontWeight: "bold" }}>
              Show Yesterday's Pool Projections
            </a>
          </p>
        ) : null}
        <br />
        {PoolVolume === -1 ? <PoolItemWideLoaderLarge /> : PoolVolume === 0 ? <div className="panel">No pool currently open.</div> : ""}
        {Pools.map((item) => (
          <>
            <section id={item.programDate + "_" + item.programCode + "_" + item.race}>
              {item.PartitionKey.startsWith("SC") ? null : <LivePoolItemWide item={item} />}
            </section>
          </>
        ))}
      </div>
    </div>
  );
};

export default LivePageYesterday;
