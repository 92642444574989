import React from "react";
import S6Howto from "./S6Howto";
import S10Howto from "./S10Howto";
import S13Howto from "./S13Howto";
import S4Howto from "./S4Howto";
import S13XHowto from "./S13XHowto";
import MegastrikeHowto from "./MegastrikeHowto";
import S1Howto from "./S1Howto";
import S8Howto from "./S8Howto";
import S5Howto from "./S5Howto";
import S7Howto from "./S7Howto";
import S11Howto from "./S11Howto";

const HowtoPage = ({ pool_id }) => {
  const renderHowtoType = (pool_id) => {
    switch (pool_id) {
      case 6:
        return <S6Howto />;
      case 10:
        return <S10Howto />;
      case 13:
        return <S13Howto />;
      case 4:
        return <S4Howto />;
      case 16:
        return <S13XHowto />;
      case 12:
        return <MegastrikeHowto />;
      case 1:
        return <S1Howto />;
      case 8:
        return <S8Howto />;
      case 5:
        return <S5Howto />;
      case 7:
        return <S7Howto />;
      case 11:
        return <S11Howto />;
      default:
        return null;
    }
  };

  const renderPageName = (pool_id) => {
    switch (pool_id) {
      case 6:
        return "How To Play - Soccer 6";
      case 10:
        return "How To Play - Soccer 10";
      case 13:
        return "How To Play - Soccer 13";
      case 4:
        return "How To Play - Soccer 4";
      case 16:
        return "How To Play - Soccer Any 13 Xtra";
      case 12:
        return "How To Play - MegaStrike 10";
      case 1:
        return "How To Play - Soccer 1";
      case 8:
        return "How To Play - Soccer 8";
      case 5:
        return "How To Play - Soccer Scores";
      case 7:
        return "How To Play - Soccer Corners";
      case 11:
        return "How To Play - Mega 10 Jackpot";
      default:
        return null;
    }
  };

  return (
    <div className="row second">
      <div className="large-12 columns">
        <h1>{renderPageName(pool_id)}</h1>
        {renderHowtoType(pool_id)}
      </div>
    </div>
  );
};

export default HowtoPage;
