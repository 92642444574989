import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ArticlePage = ({ article }) => {
  const [Article, setArticle] = useState({});
  const { id } = useParams();
  const unbundle_category = (category) => {
    switch (category) {
      case "Soccer10OfTheDay":
        return "SOCCER 10 POOL OF THE DAY";
      case "Soccer6OfTheDay":
        return "SOCCER 6 POOL OF THE DAY";
      case "Soccer4OfTheDay":
        return "SOCCER 4 POOL OF THE DAY";
      case "Soccer13OfTheDay":
        return "SOCCER 13 POOL OF THE WEEK";
      case "Soccer13XOfTheDay":
        return "SOCCER ANY 13 XTRA POOL OF THE WEEK";
      case "Mega10OfTheDay":
        return "MEGA 10 JACKPOT POOL OF THE WEEK";
      case "StoryOfWeek":
        return "STORY OF THE WEEK";
      case "Briefs":
        return "BRIEFS";
      case "Column":
        return "COLUMN";
      case "Soccer10Payout":
        return "SOCCER 10 PAYOUT";
      case "Soccer13Payout":
        return "SOCCER 13 PAYOUT";
      case "Soccer6Payout":
        return "SOCCER 6 PAYOUT";
      case "Preview":
        return "PREVIEW";
      default:
        return null;
    }
  };

  useEffect(() => {
    (async () => {
      const loadArticle = async () => {
        const temp = await (await fetch(`/api/GetArticle/${article}/${id}`)).json();
        setArticle(temp);
      };
      loadArticle();
    })();
  }, [article, id]);

  return (
    <div className="row second">
      <div className="large-12 columns">
        <div className="panel">
          <h3>{Article.title}</h3>
          <h5>{unbundle_category(Article.PartitionKey)}</h5>
          <h5>By {Article.author}</h5>
          <p dangerouslySetInnerHTML={{ __html: Article.content }} />
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;
