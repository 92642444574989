import React, { useEffect, useState } from "react";

const manage_kickoff = (kickoff_string) => {
  const date = new Date(kickoff_string);
  const dateFormatter = new Intl.DateTimeFormat("en-ZA", {
    weekday: "long",
    day: "numeric",
    month: "long",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Africa/Johannesburg",
    hour12: false,
  });
  const formattedDate = dateFormatter.format(date);
  return formattedDate.replace(",", "");
};

const PreviewPage = () => {
  const [Previews, setPreviews] = useState([]);
  const [SortPreference, setSortPreference] = useState(0);

  const changeSortOrder = () => {
    if (SortPreference === 0) {
      setSortPreference(1);
    } else {
      setSortPreference(0);
    }
  };

  useEffect(() => {
    (async () => {
      const loadPreviews = async () => {
        let loaded_previews;
        if (Previews.length === 0) {
          loaded_previews = (await (await fetch(`/api/GetPreviews`)).json()).value;
        } else {
          loaded_previews = Previews;
        }
        if (SortPreference === 0) {
          loaded_previews = loaded_previews.sort((a, b) => a.RowKey.localeCompare(b.RowKey));
        } else {
          loaded_previews = loaded_previews.sort((a, b) => a.kickoff.localeCompare(b.kickoff));
        }
        const temp = [];
        for (const preview of loaded_previews) {
          temp.push(preview);
        }
        setPreviews(temp);
      };
      loadPreviews();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SortPreference]);

  return (
    <div className="row second">
      <div className="large-12 columns">
        {SortPreference === 0 ? (
          <>
            <h1>Match Previews - Alphabetical</h1>
            <p>
              <button
                onClick={changeSortOrder}
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  color: "grey",
                  cursor: "pointer",
                }}
              >
                Sort this page by kickoff
              </button>
              &nbsp; | &nbsp;
              <a
                href="https://www.soccer6.co.za/form-guides/previews-by-pool"
                style={{
                  color: "#001489",
                  fontWeight: "bold",
                }}
              >
                Match Previews By Pool
              </a>
            </p>
          </>
        ) : (
          <>
            <h1>Match Previews - by Start Date/Time</h1>
            <p>
              <button
                onClick={changeSortOrder}
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  color: "grey",
                  cursor: "pointer",
                }}
              >
                Sort this page alphabetically
              </button>
              &nbsp; | &nbsp;
              <a
                href="https://www.soccer6.co.za/form-guides/previews-by-pool"
                style={{
                  color: "#001489",
                }}
              >
                Match Previews By Pool
              </a>
            </p>
          </>
        )}
        {Previews.map((item) => (
          <p>
            <a href={"/h2h/" + item.PartitionKey} style={{ color: "#001489", fontWeight: "bold" }}>
              {item.RowKey}
            </a>
            <br />
            <span style={{ color: "grey", fontWeight: "bold" }}>{item.comp_name}</span>
            <br />
            <span style={{ color: "#00b74f", fontWeight: "bold" }}>{manage_kickoff(item.kickoff)}</span>
            <br />
            {item.preview} <br />
            <a href={"/h2h/" + item.PartitionKey} style={{ color: "#001489", fontSize: "0.8em", fontWeight: "bold" }}>
              View Head 2 Head Page
            </a>
            {item.full_preview ? (
              <>
                &nbsp; | &nbsp;
                <a href={"/FullPreview/" + item.full_preview} style={{ color: "#001489", fontSize: "0.8em", fontWeight: "bold" }}>
                  Read Full Preview
                </a>
              </>
            ) : null}
          </p>
        ))}
      </div>
    </div>
  );
};

export default PreviewPage;
