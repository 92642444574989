import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Banners from "./Banners";
import HomePage from "./HomePage";
import HomePageDemo from "./HomePageDemo";
import PoolPage from "./PoolPage";
import PoolPageDemo from "./PoolPageDemo";
import LivePage from "./LivePage";
import LivePageYesterday from "./LivePageYesterday";
import ResultsPage from "./ResultsPage";
import TipsPage from "./TipsPage";
import ArticlePage from "./ArticlePage";
import FormGuidePage from "./FormGuidePage";
import PreviewPage from "./PreviewPage";
import HowtoPage from "./HowtoPage";
import InfoPage from "./InfoPage";
import H2HPage from "./H2HPage";
import ArchiveDates from "./ArchiveDates";
import ArchivedResults from "./ArchivedResults";
import FullPreview from "./FullPreview";
import PreviewsByPool from "./PreviewsByPool";

const App = () => {
  return (
    <div className="large-10 columns">
      <BrowserRouter>
        <Banners />
        <Routes>
          <Route path="/" element={<HomePage />} exact></Route>
          <Route path="pools-and-matches">
            <Route path="soccer-6" element={<PoolPage pool_id={6} />}></Route>
            <Route path="soccer6-advanced" element={<PoolPage pool_id={6} />}></Route>
            <Route path="soccer-10" element={<PoolPage pool_id={10} />}></Route>
            <Route path="soccer10-advanced" element={<PoolPage pool_id={10} />}></Route>
            <Route path="soccer-13" element={<PoolPage pool_id={13} />}></Route>
            <Route path="soccer13-advanced" element={<PoolPage pool_id={13} />}></Route>
            <Route path="soccer-4" element={<PoolPage pool_id={4} />}></Route>
            <Route path="soccer4-advanced" element={<PoolPage pool_id={4} />}></Route>
            <Route path="soccer-13-extra" element={<PoolPage pool_id={16} />}></Route>
            <Route path="soccer16-advanced" element={<PoolPage pool_id={16} />}></Route>
            <Route path="megastrike" element={<PoolPage pool_id={12} />}></Route>
            <Route path="soccer-1" element={<PoolPage pool_id={1} />}></Route>
            <Route path="soccer1-advanced" element={<PoolPage pool_id={1} />}></Route>
            <Route path="soccer-8" element={<PoolPage pool_id={8} />}></Route>
            <Route path="soccer8-advanced" element={<PoolPage pool_id={8} />}></Route>
            <Route path="soccer-5" element={<PoolPage pool_id={5} />}></Route>
            <Route path="soccer5-advanced" element={<PoolPage pool_id={5} />}></Route>
            <Route path="soccer-7" element={<PoolPage pool_id={7} />}></Route>
            <Route path="soccer7-advanced" element={<PoolPage pool_id={7} />}></Route>
            <Route path="soccer-11" element={<PoolPage pool_id={11} />}></Route>
            <Route path="soccer-mega-10" element={<PoolPage pool_id={11} />}></Route>
            <Route path="free2p" element={<PoolPage pool_id={20} />}></Route>
            <Route path="_for_demo_soccer-10" element={<PoolPageDemo pool_id={10} />}></Route>
            <Route path="_for_demo_soccer10-advanced" element={<PoolPageDemo pool_id={10} />}></Route>
            <Route path="_for_demo_f2p" element={<PoolPageDemo pool_id={20} />}></Route>
          </Route>
          <Route path="results-and-payouts">
            <Route path="soccer-6" element={<ResultsPage pool_id={6} />}></Route>
            <Route path="soccer-10" element={<ResultsPage pool_id={10} />}></Route>
            <Route path="soccer-13" element={<ResultsPage pool_id={13} />}></Route>
            <Route path="soccer-4" element={<ResultsPage pool_id={4} />}></Route>
            <Route path="soccer-13-extra" element={<ResultsPage pool_id={16} />}></Route>
            <Route path="soccer-12" element={<ResultsPage pool_id={12} />}></Route>
            <Route path="soccer-1" element={<ResultsPage pool_id={1} />}></Route>
            <Route path="soccer-8" element={<ResultsPage pool_id={8} />}></Route>
            <Route path="soccer-5" element={<ResultsPage pool_id={5} />}></Route>
            <Route path="soccer-7" element={<ResultsPage pool_id={7} />}></Route>
            <Route path="soccer-11" element={<ResultsPage pool_id={11} />}></Route>
          </Route>
          <Route path="top-tips">
            <Route path="soccer-6" element={<TipsPage pool_id={6} />}></Route>
            <Route path="soccer-10" element={<TipsPage pool_id={10} />}></Route>
            <Route path="soccer-13" element={<TipsPage pool_id={13} />}></Route>
            <Route path="soccer-4" element={<TipsPage pool_id={4} />}></Route>
            <Route path="soccer-13-extra" element={<TipsPage pool_id={16} />}></Route>
            <Route path="soccer-1" element={<TipsPage pool_id={1} />}></Route>
            <Route path="soccer-8" element={<TipsPage pool_id={8} />}></Route>
            <Route path="soccer-5" element={<TipsPage pool_id={5} />}></Route>
            <Route path="soccer-7" element={<TipsPage pool_id={7} />}></Route>
            <Route path="soccer-11" element={<TipsPage pool_id={11} />}></Route>
          </Route>
          <Route path="how-to-play">
            <Route path="soccer-6" element={<HowtoPage pool_id={6} />}></Route>
            <Route path="soccer-10" element={<HowtoPage pool_id={10} />}></Route>
            <Route path="soccer-13" element={<HowtoPage pool_id={13} />}></Route>
            <Route path="soccer-4" element={<HowtoPage pool_id={4} />}></Route>
            <Route path="soccer-13x" element={<HowtoPage pool_id={16} />}></Route>
            <Route path="megastrike" element={<HowtoPage pool_id={12} />}></Route>
            <Route path="soccer-1" element={<HowtoPage pool_id={1} />}></Route>
            <Route path="soccer-8" element={<HowtoPage pool_id={8} />}></Route>
            <Route path="soccer-5" element={<HowtoPage pool_id={5} />}></Route>
            <Route path="soccer-7" element={<HowtoPage pool_id={7} />}></Route>
            <Route path="soccer-11" element={<HowtoPage pool_id={11} />}></Route>
          </Route>
          <Route path="newsitem">
            <Route path="PoolOfTheDay/:id" element={<ArticlePage article={"PoolOfTheDay"} />}></Route>
            <Route path="StoryOfWeek/:id" element={<ArticlePage article={"StoryOfWeek"} />}></Route>
            <Route path="Profile/:id" element={<ArticlePage article={"Profile"} />}></Route>
            <Route path="Briefs/:id" element={<ArticlePage article={"Briefs"} />}></Route>
            <Route path="Tip/:id" element={<ArticlePage article={"Tip"} />}></Route>
            <Route path="Column/:id" element={<ArticlePage article={"Column"} />}></Route>
            <Route path="Soccer10OfTheDay/:id" element={<ArticlePage article={"Soccer10OfTheDay"} />}></Route>
            <Route path="Soccer6OfTheDay/:id" element={<ArticlePage article={"Soccer6OfTheDay"} />}></Route>
            <Route path="Soccer4OfTheDay/:id" element={<ArticlePage article={"Soccer4OfTheDay"} />}></Route>
            <Route path="Soccer13OfTheDay/:id" element={<ArticlePage article={"Soccer13OfTheDay"} />}></Route>
            <Route path="Soccer13XOfTheDay/:id" element={<ArticlePage article={"Soccer13XOfTheDay"} />}></Route>
            <Route path="Mega10OfTheDay/:id" element={<ArticlePage article={"Mega10OfTheDay"} />}></Route>
            <Route path="Soccer13Payout/:id" element={<ArticlePage article={"Soccer13Payout"} />}></Route>
            <Route path="Soccer10Payout/:id" element={<ArticlePage article={"Soccer10Payout"} />}></Route>
            <Route path="Soccer6Payout/:id" element={<ArticlePage article={"Soccer6Payout"} />}></Route>
            <Route path="Preview/:id" element={<ArticlePage article={"Preview"} />}></Route>
          </Route>
          <Route path="form-guides">
            <Route path="head-2-head" element={<FormGuidePage />}></Route>
            <Route path="previews" element={<PreviewPage />}></Route>
            <Route path="previews-by-pool" element={<PreviewsByPool />}></Route>
          </Route>
          <Route path="archives" element={<InfoPage page={"archive"} />} exact></Route>
          <Route path="archive">
            <Route path="soccer-1" element={<ArchiveDates pool_id={1} />}></Route>
            <Route path="soccer-2" element={<ArchiveDates pool_id={2} />}></Route>
            <Route path="soccer-4" element={<ArchiveDates pool_id={4} />}></Route>
            <Route path="soccer-6" element={<ArchiveDates pool_id={6} />}></Route>
            <Route path="soccer-8" element={<ArchiveDates pool_id={8} />}></Route>
            <Route path="soccer-10" element={<ArchiveDates pool_id={10} />}></Route>
            <Route path="megastrike10" element={<ArchiveDates pool_id={12} />}></Route>
            <Route path="soccer-13" element={<ArchiveDates pool_id={13} />}></Route>
            <Route path="soccer-15" element={<ArchiveDates pool_id={15} />}></Route>
            <Route path="soccer-13X" element={<ArchiveDates pool_id={16} />}></Route>
            <Route path="soccer-5" element={<ArchiveDates pool_id={5} />}></Route>
            <Route path="soccer-7" element={<ArchiveDates pool_id={7} />}></Route>
            <Route path="soccer-11" element={<ArchiveDates pool_id={11} />}></Route>
          </Route>
          <Route path="about-us" element={<InfoPage page={"about-us"} />}></Route>
          <Route path="contact-us" element={<InfoPage page={"contact-us"} />}></Route>
          <Route path="rules" element={<InfoPage page={"rules"} />}></Route>
          <Route path="h2h/:id" element={<H2HPage />}></Route>
          <Route path="archived-month/:id/:subdate" element={<ArchivedResults />}></Route>
          <Route path="live/:date_id" element={<LivePage />}></Route>
          <Route path="live-pool-projections" element={<LivePage />}></Route>
          <Route path="live-pool-projections-1" element={<LivePageYesterday />}></Route>
          <Route path="FullPreview/:id" element={<FullPreview />}></Route>
          <Route path="_for_demo" element={<HomePageDemo />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
