import React from "react";

const S5Howto = () => {
  return (
    <div className="row second">
      <div className="large-12 columns">

        <blockquote>Predict the final score in each of six matches choosing from nine options.</blockquote>
        <p>There are 9 result options in each match in the pool:</p>
        <p>The computerised tote betting system uses numbers to denote the following options:</p>

        <div className="panel">
          <ul>
            <li>1 = 1-0 win by either team</li>
            <li>2 = 2-0 win by either team</li>
            <li>3 = 3-0 win by either team</li>
            <li>4 = 2-1 win by either team</li>
            <li>5 = 3-1 win by either team</li>
            <li>6 = 3-2 win by either team</li>
            <li>7 = any other winning score</li>
            <li>8 = no score draw</li>
            <li>9 = score draw</li>
          </ul>
          <p>So when you bet, use numbers 1 to 9 to denote your choices. The same numbers are used to display results.</p>
        </div>
        <p>
          <strong>PLEASE NOTE: </strong>In Soccer Scores and other TAB soccer bets, the result of a match is the score at the end of the normal 90-minute playing
          period plus any injury time that may beplayed. Extra time and penalty shoot-outs are of no account.
        </p>
        <h2>What Does Soccer Scores Cost</h2>
        <p>The unit of betting is R2.</p>
      </div>
    </div>
  );
};

export default S5Howto;
