import React, { useEffect, useState } from "react";

const ArchiveDates = ({ pool_id }) => {
  const [YearDates, setYearDates] = useState([]);

  const renderArchiveDatesName = (pool_id) => {
    switch (pool_id) {
      case 6:
        return "Archive - Soccer 6";
      case 10:
        return "Archive - Soccer 10";
      case 13:
        return "Archive - Soccer 13";
      case 4:
        return "Archive - Soccer 4";
      case 16:
        return "Archive - Soccer Any 13 Xtra";
      case 12:
        return "Archive - MegaStrike 10";
      case 1:
        return "Archive - Soccer 1";
      case 8:
        return "Archive - Soccer 8";
      case 2:
        return "Archive - Soccer 2";
      case 15:
        return "Archive - Soccer Any 15";
      case 5:
        return "Archive - Soccer Scores";
      case 7:
        return "Archive - Soccer Corners";
      default:
        return null;
    }
  };

  useEffect(() => {
    (async () => {
      const loadYearDates = async () => {
        const results = await (await fetch(`/api/GetArchiveYearMonths/${pool_id}`)).json();
        const temp = [];
        for (const result of results) {
          temp.push(result);
        }
        setYearDates(temp);
      };
      loadYearDates();
    })();
  }, [pool_id]);

  return (
    <div className="row second">
      <div className="large-12 columns">
        <h1>{renderArchiveDatesName(pool_id)}</h1>
        {YearDates.length > 0 ? (
          YearDates.map((item, index) => (
            <div className="panel panelheader">
              <h3>{item.year}</h3>
              {YearDates[index].months.map((month_item, month_index) => (
                <a className="button button4R expand" href={"/archived-month/" + pool_id + "/" + month_item.subdate}>
                  {month_item.month}
                </a>
              ))}
            </div>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default ArchiveDates;
