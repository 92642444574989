import React from "react";

const S11Howto = () => {
  return (
    <div>
      <div class="row">
        <div class="large-6 columns">
          <div class="flex-video">
            <iframe title="Video" width="420" height="315" src="https://www.youtube.com/embed/JAB5E-uPE4Y?rel=0" frameborder="0" allowfullscreen=""></iframe>
          </div>
        </div>

        <div class="large-6 columns">
          <div class="panel">
            <h2>For Assistance or More Info:</h2>
            <p>
              Contact our customer care on 0861 444 822 or send us a mail or send us a <a href="mailto:Customercare@4racing.com">mail</a>.
            </p>
          </div>
        </div>
      </div>
      <h2>KEY MEGA 10 JACKPOT INFORMATION</h2>
      <p>The Mega-10-Jackpot pool is based on the Soccer-10 pool, except for the following differences:</p>
      <ul>
        <li>Mega-10 minimum bet and increments is R3-00 instead of R2-00 for the Soccer- 10,</li>
        <li>Mega-10 10-of-10 percentage allocation is 70% instead of 80% for the Soccer-10,</li>
        <li>10% of the Mega-10 Nett pool is carried forward to the next Mega-10 pool (but may change) to ensure a progressive Jackpot, and</li>
        <li>
          When the Mega-10 Net pool reaches or exceeds R10 million, AND the 70% (10- of-10) portion is not won, the 90% of the Net pool is combined and
          distributed to all winners who have selected the most 10 correct matches.
        </li>
      </ul>
      <p>
        As with Soccer 10, you can play a single or multiple entries. With a single entry, you select ONE option or choice for each match and the bet costs R3.
        With a multiple entry, you can choose more than one option in one or more matches.
      </p>
      <p>
        The cost is R3 per combination. To calculate the number of combinations, multiply out the total number of options or choices for each match. Then take
        that total and multiply it by 3 to calculate the cost of the bet.
      </p>
    </div>
  );
};

export default S11Howto;
