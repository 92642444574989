import React from "react";

const S1Howto = () => {
  return (
    <div className="row second">
      <div className="large-12 columns">
        <h1>How To Play - Soccer 1</h1>

        <blockquote>Soccer 1 is an exciting bet that enables you to bet on the outcome of a single match.</blockquote>
        <h2>Soccer 1 is Easy to Play</h2>
        <p>There are 6 result options in a match and they are the same as Soccer 4:</p>
        <ul>
          <li>Team A win by difference of only one goal (1-0; 3-2; 4-3; 5-4; 6-5 etc)</li>
          <li>Team A win by difference of two goals of more (2-0; 3-1; 4-1; 5-0; 6-3 etc)</li>
          <li>No-score draw (0-0 only)</li>
          <li>Score draw (1-1; 2-2 etc) </li>
          <li>Team B win by difference of only one goal</li>
          <li>Team B win by difference of two goals or more</li>
        </ul>
        <p>The computerised tote betting system uses numbers to denote the above options:</p>

        <div className="panel">
          <ol>
            <li>Win by Team A by one goal</li>
            <li>Win by Team A by two goals or more</li>
            <li>No-score draw</li>
            <li>Score draw</li>
            <li>Win by Team B by one</li>
            <li>Win by Team B by two or more</li>
          </ol>
          <p>So when you bet, use numbers 1 to 6 to denote your choices. The same numbers are used to display results.</p>
        </div>
        <p>
          <strong>PLEASE NOTE: </strong>In Soccer 1 and other TAB soccer bets, the result of a match is the score at the end of the normal 90-minute playing
          period plus any injury time that may beplayed. Extra time and penalty shoot-outs are of no account.
        </p>
        <p>
          <strong>NOTE: </strong>When using a bet slip to place your bet, mark S1 on the bet slip and mark your choices in M1 of the Soccer 4 grid.
        </p>
        <h2>What Does Soccer 1 Cost</h2>
        <p>
          The unit of betting is R1, but the minimum amount that can be invested on each result option chosen is R6, which can be increased by increments of R1.
          So for example you can bet R6, R9 or R11 on a result option, but not R5 or less. And if you chose 2 options, the cost will be a minimum of R12.
        </p>
        <p>Please note: Soccer 1 dividends are displayed to a R1 unit. So in the case of a R3 payout, you would win R18 (6 x R3) for a R6 investment.</p>
        <p>
          <strong>Test your soccer knowledge and back your team in this thrilling head-to-head bet!</strong>
        </p>
      </div>
    </div>
  );
};

export default S1Howto;
