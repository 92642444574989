import React from "react";
import PoolItemDemo from "./PoolItemDemo";

const get_pool_icon = (panel_number) => {
  if (panel_number === 1) {
    return "soccer-6-icon-4R.png";
  } else if (panel_number === 2) {
    return "soccer-10-icon-4R.png";
  } else if (panel_number === 3) {
    return "soccer-13-icon-4R.png";
  } else if (panel_number === 4) {
    return "soccer-4-icon-4R.png";
  } else if (panel_number === 5) {
    return "soccer-13ax-icon-4R.png";
  } else if (panel_number === 6) {
    // return "soccer-Megastrike-icon-4R.png";
    return "soccer-8-icon-4R.png";
  } else if (panel_number === 7) {
    // return "soccer-8-icon-4R.png";
    return "soccer-1-icon-4R.png";
  } else if (panel_number === 8) {
    // return "soccer-1-icon-4R.png";
    return "soccer-scores-icon-4R.png";
  } else if (panel_number === 9) {
    return "soccer-corners-icon-4R.png";
  }
};

const get_tab_status = (panel_number, panel_selected) => {
  if (panel_number === panel_selected) {
    return "active";
  } else {
    return "";
  }
};

const get_tab_style = (panel_number) => {
  if (panel_number === 1) {
    return { left: "0px", height: "56px" };
  } else if (panel_number === 2) {
    return { left: "55px", height: "56px" };
  } else if (panel_number === 3) {
    return { left: "110px", height: "56px" };
  } else if (panel_number === 4) {
    return { left: "165px", height: "56px" };
  } else if (panel_number === 5) {
    return { left: "220px", height: "56px" };
  } else if (panel_number === 6) {
    return { left: "275px", height: "56px" };
  } else if (panel_number === 7) {
    return { left: "330px", height: "56px" };
  } else if (panel_number === 8) {
    return { left: "385px", height: "56px" };
  } else if (panel_number === 9) {
    return { left: "440px", height: "56px" };
  }
};

const PoolPanelDemo = ({ pools, panel_selected, panel_number, volume }) => {
  return (
    <section className={get_tab_status(panel_number, panel_selected)} style={{ paddingTop: "56px" }}>
      <p className="title" data-section-title style={get_tab_style(panel_number)}>
        <a href={"#panel" + panel_number}>
          <img src={"images/" + get_pool_icon(panel_number)} alt={"pool-icon-" + panel_number} />
        </a>
      </p>
      <div className="content" data-section-content>
        {volume === 0 ? (
          <div className="panel">No pool currently open.</div>
        ) : (
          ""
        )}
        {pools.map((item) => (
          <PoolItemDemo item={item} />
        ))}
      </div>
    </section>
  );
};

export default PoolPanelDemo;
